import React, { useState } from 'react';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { Alert } from './ui/alert';
import { Button } from './ui/button';

const AudioRecorderComponent = () => {
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [error, setError] = useState<string | null>(null);
  const recorderControls = useAudioRecorder();

  const addAudioElement = (blob: Blob) => {
    setAudioBlob(blob);
  };

  const uploadAudio = async () => {
    if (!audioBlob) {
      setError('No audio recorded');
      return;
    }

    const formData = new FormData();
    formData.append('audio', audioBlob, 'recording.mp3');

    try {
      const response = await fetch('http://localhost:3001/api/mic', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Upload failed');
      }

      await response.json();
      setError(null);
    } catch (err: any) {
      setError(`Error uploading audio: ${err.message}`);
    }
  };

  return (
    <div className="m-4">
      <AudioRecorder
        onRecordingComplete={addAudioElement}
        recorderControls={recorderControls}
      />
      {audioBlob && (
        <div className="mt-4">
          <audio controls src={URL.createObjectURL(audioBlob)} />
        </div>
      )}
      <Button onClick={uploadAudio} disabled={!audioBlob} className="my-4">
        Upload Audio
      </Button>
      {error && (
        <Alert variant="destructive">
          <div>{error}</div>
        </Alert>
      )}
    </div>
  );
};

export default AudioRecorderComponent;

'use client';

import { useState, useEffect } from 'react';
import { Button } from './ui/button';
import { Input } from './ui/input';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from './ui/card';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ui/dialog';
import { useAuth } from '../contexts/AuthContext';
import { FolderSearch, LetterText, Plus, Trash2, Youtube } from 'lucide-react';
import HeaderNav from './HeaderNav';

interface Resource {
  id: string;
  title: string;
  url: string;
}

export default function ImprovedResourcesPage() {
  const [resources, setResources] = useState<Resource[]>([]);
  const [newResourceUrl, setNewResourceUrl] = useState('');
  const [newResourceTitle, setNewResourceTitle] = useState('');
  const [isAddingResource, setIsAddingResource] = useState(false);
  const { isAtLeastTeacher } = useAuth();

  useEffect(() => {
    // Fetch resources from your API here
    // For now, we'll use mock data
    setResources([
      {
        id: '1',
        title: 'Quran Recitation',
        url: 'https://www.youtube.com/embed/Nnd641CP1k8',
      },
      {
        id: '2',
        title: 'Quran Recitation',
        url: 'https://www.youtube.com/embed/Nnd641CP1k8',
      },
      {
        id: '3',
        title: 'Quran Recitation',
        url: 'https://www.youtube.com/embed/Nnd641CP1k8',
      },
    ]);
  }, []);

  const addResource = () => {
    if (newResourceUrl && newResourceTitle) {
      const videoId = extractVideoId(newResourceUrl);
      if (videoId) {
        const newResource = {
          id: Date.now().toString(),
          title: newResourceTitle,
          url: `https://www.youtube.com/embed/${videoId}`,
        };
        setResources([...resources, newResource]);
        setNewResourceUrl('');
        setNewResourceTitle('');
        setIsAddingResource(false);
      } else {
        alert('Invalid YouTube URL');
      }
    }
  };

  const removeResource = (id: string) => {
    setResources(resources.filter((resource) => resource.id !== id));
  };

  const extractVideoId = (url: string) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|v=)([^#]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  return (
    <div className="bg-gray-100">
      <HeaderNav title={<span className="font-bold"></span>} />
      <div className="container mx-auto px-4 py-8 min-h-screen">
        <div className="flex flex-col sm:flex-row items-center justify-between mb-8 gap-4">
          <h1 className="text-3xl font-bold flex items-center">
            <FolderSearch className="mr-2 h-8 w-8 text-primary" />
            Resources
          </h1>
          {isAtLeastTeacher && (
            <Dialog open={isAddingResource} onOpenChange={setIsAddingResource}>
              <DialogTrigger asChild>
                <Button>
                  <Plus className="mr-2 h-4 w-4" /> Add Resource
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Add New Resource</DialogTitle>
                  <DialogDescription>
                    Enter the details of the new YouTube resource you want to
                    add.
                  </DialogDescription>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                  <Input
                    icon={
                      <LetterText className="w-5 h-5 flex-shrink-0 opacity-50" />
                    }
                    type="text"
                    placeholder="Resource Title"
                    value={newResourceTitle}
                    onChange={(e) => setNewResourceTitle(e.target.value)}
                  />
                  <Input
                    icon={
                      <Youtube className="w-5 h-5 flex-shrink-0 opacity-50" />
                    }
                    type="text"
                    placeholder="YouTube URL"
                    value={newResourceUrl}
                    onChange={(e) => setNewResourceUrl(e.target.value)}
                  />
                </div>
                <DialogFooter>
                  <Button onClick={addResource}>Add Resource</Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          )}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {resources.map((resource, index) => (
            <div key={resource.id}>
              <Card className="overflow-hidden h-full flex flex-col">
                <CardHeader className="p-4">
                  <CardTitle className="text-lg line-clamp-1">
                    {resource.title}
                  </CardTitle>
                  <CardDescription className="flex items-center">
                    <Youtube className="mr-2 h-4 w-4 text-red-500" />
                    YouTube Video
                  </CardDescription>
                </CardHeader>
                <CardContent className="p-0 flex-grow">
                  <div className="aspect-video">
                    <iframe
                      width="100%"
                      height="100%"
                      src={resource.url}
                      title={resource.title}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </CardContent>
                {isAtLeastTeacher && (
                  <div className="p-4 mt-auto">
                    <Button
                      onClick={() => removeResource(resource.id)}
                      className="w-full bg-red-500 hover:bg-red-600 text-white"
                    >
                      <Trash2 className="mr-2 h-4 w-4" /> Remove
                    </Button>
                  </div>
                )}
              </Card>
            </div>
          ))}
        </div>
        {resources.length === 0 && (
          <div className="text-center text-gray-500 mt-8">
            No resources available.{' '}
            {isAtLeastTeacher && 'Add some resources to get started!'}
          </div>
        )}
      </div>
    </div>
  );
}

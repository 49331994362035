// let d = [];
// await fetch('https://api.quran.com/api/v4/chapters')
//   .then((response) => response.json())
//   .then((data) => {
//     let counter = 0;
//     d = d.concat(
//       data.chapters.map((e, i) => {
//         const lastVerse = counter + 1;
//         const startVerse = lastVerse;
//         const endVerse = startVerse + e.verses_count - 1;
//         counter = endVerse;
//         const surahNumber = e.id;

//         return { name: e.name_simple, surahNumber, startVerse, endVerse };
//       })
//     );
//   });

// const convertToMap = (array) => {
//   return array.reduce((acc, surah) => {
//     acc[surah.surahNumber] = surah;
//     return acc;
//   }, {});
// };
// const surahMap = convertToMap(surahs);

export interface Surah {
  surahNumber: number;
  name: string;
  startVerse: number;
  endVerse: number;
}

export const surahs: Surah[] = [
  { name: 'Al-Fatihah', surahNumber: 1, startVerse: 1, endVerse: 7 },
  { name: 'Al-Baqarah', surahNumber: 2, startVerse: 8, endVerse: 293 },
  { name: "Ali 'Imran", surahNumber: 3, startVerse: 294, endVerse: 493 },
  { name: 'An-Nisa', surahNumber: 4, startVerse: 494, endVerse: 669 },
  { name: "Al-Ma'idah", surahNumber: 5, startVerse: 670, endVerse: 789 },
  { name: "Al-An'am", surahNumber: 6, startVerse: 790, endVerse: 954 },
  { name: "Al-A'raf", surahNumber: 7, startVerse: 955, endVerse: 1160 },
  { name: 'Al-Anfal', surahNumber: 8, startVerse: 1161, endVerse: 1235 },
  { name: 'At-Tawbah', surahNumber: 9, startVerse: 1236, endVerse: 1364 },
  { name: 'Yunus', surahNumber: 10, startVerse: 1365, endVerse: 1473 },
  { name: 'Hud', surahNumber: 11, startVerse: 1474, endVerse: 1596 },
  { name: 'Yusuf', surahNumber: 12, startVerse: 1597, endVerse: 1707 },
  { name: "Ar-Ra'd", surahNumber: 13, startVerse: 1708, endVerse: 1750 },
  { name: 'Ibrahim', surahNumber: 14, startVerse: 1751, endVerse: 1802 },
  { name: 'Al-Hijr', surahNumber: 15, startVerse: 1803, endVerse: 1901 },
  { name: 'An-Nahl', surahNumber: 16, startVerse: 1902, endVerse: 2029 },
  { name: 'Al-Isra', surahNumber: 17, startVerse: 2030, endVerse: 2140 },
  { name: 'Al-Kahf', surahNumber: 18, startVerse: 2141, endVerse: 2250 },
  { name: 'Maryam', surahNumber: 19, startVerse: 2251, endVerse: 2348 },
  { name: 'Taha', surahNumber: 20, startVerse: 2349, endVerse: 2483 },
  { name: 'Al-Anbya', surahNumber: 21, startVerse: 2484, endVerse: 2595 },
  { name: 'Al-Hajj', surahNumber: 22, startVerse: 2596, endVerse: 2673 },
  { name: "Al-Mu'minun", surahNumber: 23, startVerse: 2674, endVerse: 2791 },
  { name: 'An-Nur', surahNumber: 24, startVerse: 2792, endVerse: 2855 },
  { name: 'Al-Furqan', surahNumber: 25, startVerse: 2856, endVerse: 2932 },
  { name: "Ash-Shu'ara", surahNumber: 26, startVerse: 2933, endVerse: 3159 },
  { name: 'An-Naml', surahNumber: 27, startVerse: 3160, endVerse: 3252 },
  { name: 'Al-Qasas', surahNumber: 28, startVerse: 3253, endVerse: 3340 },
  { name: "Al-'Ankabut", surahNumber: 29, startVerse: 3341, endVerse: 3409 },
  { name: 'Ar-Rum', surahNumber: 30, startVerse: 3410, endVerse: 3469 },
  { name: 'Luqman', surahNumber: 31, startVerse: 3470, endVerse: 3503 },
  { name: 'As-Sajdah', surahNumber: 32, startVerse: 3504, endVerse: 3533 },
  { name: 'Al-Ahzab', surahNumber: 33, startVerse: 3534, endVerse: 3606 },
  { name: 'Saba', surahNumber: 34, startVerse: 3607, endVerse: 3660 },
  { name: 'Fatir', surahNumber: 35, startVerse: 3661, endVerse: 3705 },
  { name: 'Ya-Sin', surahNumber: 36, startVerse: 3706, endVerse: 3788 },
  { name: 'As-Saffat', surahNumber: 37, startVerse: 3789, endVerse: 3970 },
  { name: 'Sad', surahNumber: 38, startVerse: 3971, endVerse: 4058 },
  { name: 'Az-Zumar', surahNumber: 39, startVerse: 4059, endVerse: 4133 },
  { name: 'Ghafir', surahNumber: 40, startVerse: 4134, endVerse: 4218 },
  { name: 'Fussilat', surahNumber: 41, startVerse: 4219, endVerse: 4272 },
  { name: 'Ash-Shuraa', surahNumber: 42, startVerse: 4273, endVerse: 4325 },
  { name: 'Az-Zukhruf', surahNumber: 43, startVerse: 4326, endVerse: 4414 },
  { name: 'Ad-Dukhan', surahNumber: 44, startVerse: 4415, endVerse: 4473 },
  { name: 'Al-Jathiyah', surahNumber: 45, startVerse: 4474, endVerse: 4510 },
  { name: 'Al-Ahqaf', surahNumber: 46, startVerse: 4511, endVerse: 4545 },
  { name: 'Muhammad', surahNumber: 47, startVerse: 4546, endVerse: 4583 },
  { name: 'Al-Fath', surahNumber: 48, startVerse: 4584, endVerse: 4612 },
  { name: 'Al-Hujurat', surahNumber: 49, startVerse: 4613, endVerse: 4630 },
  { name: 'Qaf', surahNumber: 50, startVerse: 4631, endVerse: 4675 },
  {
    name: 'Adh-Dhariyat',
    surahNumber: 51,
    startVerse: 4676,
    endVerse: 4735,
  },
  { name: 'At-Tur', surahNumber: 52, startVerse: 4736, endVerse: 4784 },
  { name: 'An-Najm', surahNumber: 53, startVerse: 4785, endVerse: 4846 },
  { name: 'Al-Qamar', surahNumber: 54, startVerse: 4847, endVerse: 4901 },
  { name: 'Ar-Rahman', surahNumber: 55, startVerse: 4902, endVerse: 4979 },
  { name: "Al-Waqi'ah", surahNumber: 56, startVerse: 4980, endVerse: 5075 },
  { name: 'Al-Hadid', surahNumber: 57, startVerse: 5076, endVerse: 5104 },
  { name: 'Al-Mujadila', surahNumber: 58, startVerse: 5105, endVerse: 5126 },
  { name: 'Al-Hashr', surahNumber: 59, startVerse: 5127, endVerse: 5150 },
  {
    name: 'Al-Mumtahanah',
    surahNumber: 60,
    startVerse: 5151,
    endVerse: 5163,
  },
  { name: 'As-Saf', surahNumber: 61, startVerse: 5164, endVerse: 5177 },
  { name: "Al-Jumu'ah", surahNumber: 62, startVerse: 5178, endVerse: 5188 },
  {
    name: 'Al-Munafiqun',
    surahNumber: 63,
    startVerse: 5189,
    endVerse: 5199,
  },
  { name: 'At-Taghabun', surahNumber: 64, startVerse: 5200, endVerse: 5217 },
  { name: 'At-Talaq', surahNumber: 65, startVerse: 5218, endVerse: 5229 },
  { name: 'At-Tahrim', surahNumber: 66, startVerse: 5230, endVerse: 5241 },
  { name: 'Al-Mulk', surahNumber: 67, startVerse: 5242, endVerse: 5271 },
  { name: 'Al-Qalam', surahNumber: 68, startVerse: 5272, endVerse: 5323 },
  { name: 'Al-Haqqah', surahNumber: 69, startVerse: 5324, endVerse: 5375 },
  { name: "Al-Ma'arij", surahNumber: 70, startVerse: 5376, endVerse: 5419 },
  { name: 'Nuh', surahNumber: 71, startVerse: 5420, endVerse: 5447 },
  { name: 'Al-Jinn', surahNumber: 72, startVerse: 5448, endVerse: 5475 },
  {
    name: 'Al-Muzzammil',
    surahNumber: 73,
    startVerse: 5476,
    endVerse: 5495,
  },
  {
    name: 'Al-Muddaththir',
    surahNumber: 74,
    startVerse: 5496,
    endVerse: 5551,
  },
  { name: 'Al-Qiyamah', surahNumber: 75, startVerse: 5552, endVerse: 5591 },
  { name: 'Al-Insan', surahNumber: 76, startVerse: 5592, endVerse: 5622 },
  { name: 'Al-Mursalat', surahNumber: 77, startVerse: 5623, endVerse: 5672 },
  { name: 'An-Naba', surahNumber: 78, startVerse: 5673, endVerse: 5712 },
  { name: "An-Nazi'at", surahNumber: 79, startVerse: 5713, endVerse: 5758 },
  { name: "'Abasa", surahNumber: 80, startVerse: 5759, endVerse: 5800 },
  { name: 'At-Takwir', surahNumber: 81, startVerse: 5801, endVerse: 5829 },
  { name: 'Al-Infitar', surahNumber: 82, startVerse: 5830, endVerse: 5848 },
  {
    name: 'Al-Mutaffifin',
    surahNumber: 83,
    startVerse: 5849,
    endVerse: 5884,
  },
  { name: 'Al-Inshiqaq', surahNumber: 84, startVerse: 5885, endVerse: 5909 },
  { name: 'Al-Buruj', surahNumber: 85, startVerse: 5910, endVerse: 5931 },
  { name: 'At-Tariq', surahNumber: 86, startVerse: 5932, endVerse: 5948 },
  { name: "Al-A'la", surahNumber: 87, startVerse: 5949, endVerse: 5967 },
  {
    name: 'Al-Ghashiyah',
    surahNumber: 88,
    startVerse: 5968,
    endVerse: 5993,
  },
  { name: 'Al-Fajr', surahNumber: 89, startVerse: 5994, endVerse: 6023 },
  { name: 'Al-Balad', surahNumber: 90, startVerse: 6024, endVerse: 6043 },
  { name: 'Ash-Shams', surahNumber: 91, startVerse: 6044, endVerse: 6058 },
  { name: 'Al-Layl', surahNumber: 92, startVerse: 6059, endVerse: 6079 },
  { name: 'Ad-Duhaa', surahNumber: 93, startVerse: 6080, endVerse: 6090 },
  { name: 'Ash-Sharh', surahNumber: 94, startVerse: 6091, endVerse: 6098 },
  { name: 'At-Tin', surahNumber: 95, startVerse: 6099, endVerse: 6106 },
  { name: "Al-'Alaq", surahNumber: 96, startVerse: 6107, endVerse: 6125 },
  { name: 'Al-Qadr', surahNumber: 97, startVerse: 6126, endVerse: 6130 },
  { name: 'Al-Bayyinah', surahNumber: 98, startVerse: 6131, endVerse: 6138 },
  { name: 'Az-Zalzalah', surahNumber: 99, startVerse: 6139, endVerse: 6146 },
  { name: "Al-'Adiyat", surahNumber: 100, startVerse: 6147, endVerse: 6157 },
  { name: "Al-Qari'ah", surahNumber: 101, startVerse: 6158, endVerse: 6168 },
  {
    name: 'At-Takathur',
    surahNumber: 102,
    startVerse: 6169,
    endVerse: 6176,
  },
  { name: "Al-'Asr", surahNumber: 103, startVerse: 6177, endVerse: 6179 },
  { name: 'Al-Humazah', surahNumber: 104, startVerse: 6180, endVerse: 6188 },
  { name: 'Al-Fil', surahNumber: 105, startVerse: 6189, endVerse: 6193 },
  { name: 'Quraysh', surahNumber: 106, startVerse: 6194, endVerse: 6197 },
  { name: "Al-Ma'un", surahNumber: 107, startVerse: 6198, endVerse: 6204 },
  { name: 'Al-Kawthar', surahNumber: 108, startVerse: 6205, endVerse: 6207 },
  { name: 'Al-Kafirun', surahNumber: 109, startVerse: 6208, endVerse: 6213 },
  { name: 'An-Nasr', surahNumber: 110, startVerse: 6214, endVerse: 6216 },
  { name: 'Al-Masad', surahNumber: 111, startVerse: 6217, endVerse: 6221 },
  { name: 'Al-Ikhlas', surahNumber: 112, startVerse: 6222, endVerse: 6225 },
  { name: 'Al-Falaq', surahNumber: 113, startVerse: 6226, endVerse: 6230 },
  { name: 'An-Nas', surahNumber: 114, startVerse: 6231, endVerse: 6236 },
];

export const surahMap = {
  1: { name: 'Al-Fatihah', surahNumber: 1, startVerse: 1, endVerse: 7 },
  2: { name: 'Al-Baqarah', surahNumber: 2, startVerse: 8, endVerse: 293 },
  3: {
    name: "Ali 'Imran",
    surahNumber: 3,
    startVerse: 294,
    endVerse: 493,
  },
  4: { name: 'An-Nisa', surahNumber: 4, startVerse: 494, endVerse: 669 },
  5: {
    name: "Al-Ma'idah",
    surahNumber: 5,
    startVerse: 670,
    endVerse: 789,
  },
  6: { name: "Al-An'am", surahNumber: 6, startVerse: 790, endVerse: 954 },
  7: { name: "Al-A'raf", surahNumber: 7, startVerse: 955, endVerse: 1160 },
  8: {
    name: 'Al-Anfal',
    surahNumber: 8,
    startVerse: 1161,
    endVerse: 1235,
  },
  9: {
    name: 'At-Tawbah',
    surahNumber: 9,
    startVerse: 1236,
    endVerse: 1364,
  },
  10: { name: 'Yunus', surahNumber: 10, startVerse: 1365, endVerse: 1473 },
  11: { name: 'Hud', surahNumber: 11, startVerse: 1474, endVerse: 1596 },
  12: { name: 'Yusuf', surahNumber: 12, startVerse: 1597, endVerse: 1707 },
  13: {
    name: "Ar-Ra'd",
    surahNumber: 13,
    startVerse: 1708,
    endVerse: 1750,
  },
  14: {
    name: 'Ibrahim',
    surahNumber: 14,
    startVerse: 1751,
    endVerse: 1802,
  },
  15: {
    name: 'Al-Hijr',
    surahNumber: 15,
    startVerse: 1803,
    endVerse: 1901,
  },
  16: {
    name: 'An-Nahl',
    surahNumber: 16,
    startVerse: 1902,
    endVerse: 2029,
  },
  17: {
    name: 'Al-Isra',
    surahNumber: 17,
    startVerse: 2030,
    endVerse: 2140,
  },
  18: {
    name: 'Al-Kahf',
    surahNumber: 18,
    startVerse: 2141,
    endVerse: 2250,
  },
  19: { name: 'Maryam', surahNumber: 19, startVerse: 2251, endVerse: 2348 },
  20: { name: 'Taha', surahNumber: 20, startVerse: 2349, endVerse: 2483 },
  21: {
    name: 'Al-Anbya',
    surahNumber: 21,
    startVerse: 2484,
    endVerse: 2595,
  },
  22: {
    name: 'Al-Hajj',
    surahNumber: 22,
    startVerse: 2596,
    endVerse: 2673,
  },
  23: {
    name: "Al-Mu'minun",
    surahNumber: 23,
    startVerse: 2674,
    endVerse: 2791,
  },
  24: { name: 'An-Nur', surahNumber: 24, startVerse: 2792, endVerse: 2855 },
  25: {
    name: 'Al-Furqan',
    surahNumber: 25,
    startVerse: 2856,
    endVerse: 2932,
  },
  26: {
    name: "Ash-Shu'ara",
    surahNumber: 26,
    startVerse: 2933,
    endVerse: 3159,
  },
  27: {
    name: 'An-Naml',
    surahNumber: 27,
    startVerse: 3160,
    endVerse: 3252,
  },
  28: {
    name: 'Al-Qasas',
    surahNumber: 28,
    startVerse: 3253,
    endVerse: 3340,
  },
  29: {
    name: "Al-'Ankabut",
    surahNumber: 29,
    startVerse: 3341,
    endVerse: 3409,
  },
  30: { name: 'Ar-Rum', surahNumber: 30, startVerse: 3410, endVerse: 3469 },
  31: { name: 'Luqman', surahNumber: 31, startVerse: 3470, endVerse: 3503 },
  32: {
    name: 'As-Sajdah',
    surahNumber: 32,
    startVerse: 3504,
    endVerse: 3533,
  },
  33: {
    name: 'Al-Ahzab',
    surahNumber: 33,
    startVerse: 3534,
    endVerse: 3606,
  },
  34: { name: 'Saba', surahNumber: 34, startVerse: 3607, endVerse: 3660 },
  35: { name: 'Fatir', surahNumber: 35, startVerse: 3661, endVerse: 3705 },
  36: { name: 'Ya-Sin', surahNumber: 36, startVerse: 3706, endVerse: 3788 },
  37: {
    name: 'As-Saffat',
    surahNumber: 37,
    startVerse: 3789,
    endVerse: 3970,
  },
  38: { name: 'Sad', surahNumber: 38, startVerse: 3971, endVerse: 4058 },
  39: {
    name: 'Az-Zumar',
    surahNumber: 39,
    startVerse: 4059,
    endVerse: 4133,
  },
  40: { name: 'Ghafir', surahNumber: 40, startVerse: 4134, endVerse: 4218 },
  41: {
    name: 'Fussilat',
    surahNumber: 41,
    startVerse: 4219,
    endVerse: 4272,
  },
  42: {
    name: 'Ash-Shuraa',
    surahNumber: 42,
    startVerse: 4273,
    endVerse: 4325,
  },
  43: {
    name: 'Az-Zukhruf',
    surahNumber: 43,
    startVerse: 4326,
    endVerse: 4414,
  },
  44: {
    name: 'Ad-Dukhan',
    surahNumber: 44,
    startVerse: 4415,
    endVerse: 4473,
  },
  45: {
    name: 'Al-Jathiyah',
    surahNumber: 45,
    startVerse: 4474,
    endVerse: 4510,
  },
  46: {
    name: 'Al-Ahqaf',
    surahNumber: 46,
    startVerse: 4511,
    endVerse: 4545,
  },
  47: {
    name: 'Muhammad',
    surahNumber: 47,
    startVerse: 4546,
    endVerse: 4583,
  },
  48: {
    name: 'Al-Fath',
    surahNumber: 48,
    startVerse: 4584,
    endVerse: 4612,
  },
  49: {
    name: 'Al-Hujurat',
    surahNumber: 49,
    startVerse: 4613,
    endVerse: 4630,
  },
  50: { name: 'Qaf', surahNumber: 50, startVerse: 4631, endVerse: 4675 },
  51: {
    name: 'Adh-Dhariyat',
    surahNumber: 51,
    startVerse: 4676,
    endVerse: 4735,
  },
  52: { name: 'At-Tur', surahNumber: 52, startVerse: 4736, endVerse: 4784 },
  53: {
    name: 'An-Najm',
    surahNumber: 53,
    startVerse: 4785,
    endVerse: 4846,
  },
  54: {
    name: 'Al-Qamar',
    surahNumber: 54,
    startVerse: 4847,
    endVerse: 4901,
  },
  55: {
    name: 'Ar-Rahman',
    surahNumber: 55,
    startVerse: 4902,
    endVerse: 4979,
  },
  56: {
    name: "Al-Waqi'ah",
    surahNumber: 56,
    startVerse: 4980,
    endVerse: 5075,
  },
  57: {
    name: 'Al-Hadid',
    surahNumber: 57,
    startVerse: 5076,
    endVerse: 5104,
  },
  58: {
    name: 'Al-Mujadila',
    surahNumber: 58,
    startVerse: 5105,
    endVerse: 5126,
  },
  59: {
    name: 'Al-Hashr',
    surahNumber: 59,
    startVerse: 5127,
    endVerse: 5150,
  },
  60: {
    name: 'Al-Mumtahanah',
    surahNumber: 60,
    startVerse: 5151,
    endVerse: 5163,
  },
  61: { name: 'As-Saf', surahNumber: 61, startVerse: 5164, endVerse: 5177 },
  62: {
    name: "Al-Jumu'ah",
    surahNumber: 62,
    startVerse: 5178,
    endVerse: 5188,
  },
  63: {
    name: 'Al-Munafiqun',
    surahNumber: 63,
    startVerse: 5189,
    endVerse: 5199,
  },
  64: {
    name: 'At-Taghabun',
    surahNumber: 64,
    startVerse: 5200,
    endVerse: 5217,
  },
  65: {
    name: 'At-Talaq',
    surahNumber: 65,
    startVerse: 5218,
    endVerse: 5229,
  },
  66: {
    name: 'At-Tahrim',
    surahNumber: 66,
    startVerse: 5230,
    endVerse: 5241,
  },
  67: {
    name: 'Al-Mulk',
    surahNumber: 67,
    startVerse: 5242,
    endVerse: 5271,
  },
  68: {
    name: 'Al-Qalam',
    surahNumber: 68,
    startVerse: 5272,
    endVerse: 5323,
  },
  69: {
    name: 'Al-Haqqah',
    surahNumber: 69,
    startVerse: 5324,
    endVerse: 5375,
  },
  70: {
    name: "Al-Ma'arij",
    surahNumber: 70,
    startVerse: 5376,
    endVerse: 5419,
  },
  71: { name: 'Nuh', surahNumber: 71, startVerse: 5420, endVerse: 5447 },
  72: {
    name: 'Al-Jinn',
    surahNumber: 72,
    startVerse: 5448,
    endVerse: 5475,
  },
  73: {
    name: 'Al-Muzzammil',
    surahNumber: 73,
    startVerse: 5476,
    endVerse: 5495,
  },
  74: {
    name: 'Al-Muddaththir',
    surahNumber: 74,
    startVerse: 5496,
    endVerse: 5551,
  },
  75: {
    name: 'Al-Qiyamah',
    surahNumber: 75,
    startVerse: 5552,
    endVerse: 5591,
  },
  76: {
    name: 'Al-Insan',
    surahNumber: 76,
    startVerse: 5592,
    endVerse: 5622,
  },
  77: {
    name: 'Al-Mursalat',
    surahNumber: 77,
    startVerse: 5623,
    endVerse: 5672,
  },
  78: {
    name: 'An-Naba',
    surahNumber: 78,
    startVerse: 5673,
    endVerse: 5712,
  },
  79: {
    name: "An-Nazi'at",
    surahNumber: 79,
    startVerse: 5713,
    endVerse: 5758,
  },
  80: { name: "'Abasa", surahNumber: 80, startVerse: 5759, endVerse: 5800 },
  81: {
    name: 'At-Takwir',
    surahNumber: 81,
    startVerse: 5801,
    endVerse: 5829,
  },
  82: {
    name: 'Al-Infitar',
    surahNumber: 82,
    startVerse: 5830,
    endVerse: 5848,
  },
  83: {
    name: 'Al-Mutaffifin',
    surahNumber: 83,
    startVerse: 5849,
    endVerse: 5884,
  },
  84: {
    name: 'Al-Inshiqaq',
    surahNumber: 84,
    startVerse: 5885,
    endVerse: 5909,
  },
  85: {
    name: 'Al-Buruj',
    surahNumber: 85,
    startVerse: 5910,
    endVerse: 5931,
  },
  86: {
    name: 'At-Tariq',
    surahNumber: 86,
    startVerse: 5932,
    endVerse: 5948,
  },
  87: {
    name: "Al-A'la",
    surahNumber: 87,
    startVerse: 5949,
    endVerse: 5967,
  },
  88: {
    name: 'Al-Ghashiyah',
    surahNumber: 88,
    startVerse: 5968,
    endVerse: 5993,
  },
  89: {
    name: 'Al-Fajr',
    surahNumber: 89,
    startVerse: 5994,
    endVerse: 6023,
  },
  90: {
    name: 'Al-Balad',
    surahNumber: 90,
    startVerse: 6024,
    endVerse: 6043,
  },
  91: {
    name: 'Ash-Shams',
    surahNumber: 91,
    startVerse: 6044,
    endVerse: 6058,
  },
  92: {
    name: 'Al-Layl',
    surahNumber: 92,
    startVerse: 6059,
    endVerse: 6079,
  },
  93: {
    name: 'Ad-Duhaa',
    surahNumber: 93,
    startVerse: 6080,
    endVerse: 6090,
  },
  94: {
    name: 'Ash-Sharh',
    surahNumber: 94,
    startVerse: 6091,
    endVerse: 6098,
  },
  95: { name: 'At-Tin', surahNumber: 95, startVerse: 6099, endVerse: 6106 },
  96: {
    name: "Al-'Alaq",
    surahNumber: 96,
    startVerse: 6107,
    endVerse: 6125,
  },
  97: {
    name: 'Al-Qadr',
    surahNumber: 97,
    startVerse: 6126,
    endVerse: 6130,
  },
  98: {
    name: 'Al-Bayyinah',
    surahNumber: 98,
    startVerse: 6131,
    endVerse: 6138,
  },
  99: {
    name: 'Az-Zalzalah',
    surahNumber: 99,
    startVerse: 6139,
    endVerse: 6146,
  },
  100: {
    name: "Al-'Adiyat",
    surahNumber: 100,
    startVerse: 6147,
    endVerse: 6157,
  },
  101: {
    name: "Al-Qari'ah",
    surahNumber: 101,
    startVerse: 6158,
    endVerse: 6168,
  },
  102: {
    name: 'At-Takathur',
    surahNumber: 102,
    startVerse: 6169,
    endVerse: 6176,
  },
  103: {
    name: "Al-'Asr",
    surahNumber: 103,
    startVerse: 6177,
    endVerse: 6179,
  },
  104: {
    name: 'Al-Humazah',
    surahNumber: 104,
    startVerse: 6180,
    endVerse: 6188,
  },
  105: {
    name: 'Al-Fil',
    surahNumber: 105,
    startVerse: 6189,
    endVerse: 6193,
  },
  106: {
    name: 'Quraysh',
    surahNumber: 106,
    startVerse: 6194,
    endVerse: 6197,
  },
  107: {
    name: "Al-Ma'un",
    surahNumber: 107,
    startVerse: 6198,
    endVerse: 6204,
  },
  108: {
    name: 'Al-Kawthar',
    surahNumber: 108,
    startVerse: 6205,
    endVerse: 6207,
  },
  109: {
    name: 'Al-Kafirun',
    surahNumber: 109,
    startVerse: 6208,
    endVerse: 6213,
  },
  110: {
    name: 'An-Nasr',
    surahNumber: 110,
    startVerse: 6214,
    endVerse: 6216,
  },
  111: {
    name: 'Al-Masad',
    surahNumber: 111,
    startVerse: 6217,
    endVerse: 6221,
  },
  112: {
    name: 'Al-Ikhlas',
    surahNumber: 112,
    startVerse: 6222,
    endVerse: 6225,
  },
  113: {
    name: 'Al-Falaq',
    surahNumber: 113,
    startVerse: 6226,
    endVerse: 6230,
  },
  114: {
    name: 'An-Nas',
    surahNumber: 114,
    startVerse: 6231,
    endVerse: 6236,
  },
};

export const surahNameMap: Record<number, string> = {
  1: 'Al-Fatihah',
  2: 'Al-Baqarah',
  3: "Ali 'Imran",
  4: 'An-Nisa',
  5: "Al-Ma'idah",
  6: "Al-An'am",
  7: "Al-A'raf",
  8: 'Al-Anfal',
  9: 'At-Tawbah',
  10: 'Yunus',
  11: 'Hud',
  12: 'Yusuf',
  13: "Ar-Ra'd",
  14: 'Ibrahim',
  15: 'Al-Hijr',
  16: 'An-Nahl',
  17: 'Al-Isra',
  18: 'Al-Kahf',
  19: 'Maryam',
  20: 'Taha',
  21: 'Al-Anbya',
  22: 'Al-Hajj',
  23: "Al-Mu'minun",
  24: 'An-Nur',
  25: 'Al-Furqan',
  26: "Ash-Shu'ara",
  27: 'An-Naml',
  28: 'Al-Qasas',
  29: "Al-'Ankabut",
  30: 'Ar-Rum',
  31: 'Luqman',
  32: 'As-Sajdah',
  33: 'Al-Ahzab',
  34: 'Saba',
  35: 'Fatir',
  36: 'Ya-Sin',
  37: 'As-Saffat',
  38: 'Sad',
  39: 'Az-Zumar',
  40: 'Ghafir',
  41: 'Fussilat',
  42: 'Ash-Shuraa',
  43: 'Az-Zukhruf',
  44: 'Ad-Dukhan',
  45: 'Al-Jathiyah',
  46: 'Al-Ahqaf',
  47: 'Muhammad',
  48: 'Al-Fath',
  49: 'Al-Hujurat',
  50: 'Qaf',
  51: 'Adh-Dhariyat',
  52: 'At-Tur',
  53: 'An-Najm',
  54: 'Al-Qamar',
  55: 'Ar-Rahman',
  56: "Al-Waqi'ah",
  57: 'Al-Hadid',
  58: 'Al-Mujadila',
  59: 'Al-Hashr',
  60: 'Al-Mumtahanah',
  61: 'As-Saf',
  62: "Al-Jumu'ah",
  63: 'Al-Munafiqun',
  64: 'At-Taghabun',
  65: 'At-Talaq',
  66: 'At-Tahrim',
  67: 'Al-Mulk',
  68: 'Al-Qalam',
  69: 'Al-Haqqah',
  70: "Al-Ma'arij",
  71: 'Nuh',
  72: 'Al-Jinn',
  73: 'Al-Muzzammil',
  74: 'Al-Muddaththir',
  75: 'Al-Qiyamah',
  76: 'Al-Insan',
  77: 'Al-Mursalat',
  78: 'An-Naba',
  79: "An-Nazi'at",
  80: "'Abasa",
  81: 'At-Takwir',
  82: 'Al-Infitar',
  83: 'Al-Mutaffifin',
  84: 'Al-Inshiqaq',
  85: 'Al-Buruj',
  86: 'At-Tariq',
  87: "Al-A'la",
  88: 'Al-Ghashiyah',
  89: 'Al-Fajr',
  90: 'Al-Balad',
  91: 'Ash-Shams',
  92: 'Al-Layl',
  93: 'Ad-Duhaa',
  94: 'Ash-Sharh',
  95: 'At-Tin',
  96: "Al-'Alaq",
  97: 'Al-Qadr',
  98: 'Al-Bayyinah',
  99: 'Az-Zalzalah',
  100: "Al-'Adiyat",
  101: "Al-Qari'ah",
  102: 'At-Takathur',
  103: "Al-'Asr",
  104: 'Al-Humazah',
  105: 'Al-Fil',
  106: 'Quraysh',
  107: "Al-Ma'un",
  108: 'Al-Kawthar',
  109: 'Al-Kafirun',
  110: 'An-Nasr',
  111: 'Al-Masad',
  112: 'Al-Ikhlas',
  113: 'Al-Falaq',
  114: 'An-Nas',
};

import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function pathHash(path: string) {
  return path.replace(/[^a-zA-Z]/g, '');
}

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useGetUsers } from '../hooks/useGetUsers';
import HeaderNav from './HeaderNav';
import {
  CircleAlert,
  CircleCheck,
  CircleX,
  Search,
  UserIcon,
  Download,
} from 'lucide-react';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './ui/select';
import { Card, CardContent } from './ui/card';
import { Input } from './ui/input';
import { Skeleton } from './ui/skeleton';
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar';
import { Button } from './ui/button';
import api from '../services/axios';
import { toast } from '../hooks/useToast';
import { AxiosError } from 'axios';
import { BackendError } from '../types/error';
import { User } from '../types/common';

export function Students() {
  const [selectedDate, setSelectedDate] = useState<Date>(() => {
    const now = new Date();
    return new Date(
      Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 12, 0, 0)
    );
  });
  const { users, loading, error } = useGetUsers(selectedDate);
  const [apiError, setApiError] = useState<string | null>(null);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [attendance, setAttendance] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (users.length > 0) {
      setFilteredUsers(users);
    }
    setAttendance({});
  }, [users]);

  const filterItems = (searchTerm: React.ChangeEvent<HTMLInputElement>) => {
    const term = searchTerm.target.value.toLowerCase();
    const filteredItems = users.filter((user: User) => {
      const name = `${user.name}`.toLowerCase();
      return name.includes(term);
    });
    setFilteredUsers(filteredItems);
  };

  const handleAttendanceChange = (userId: number, value: string) => {
    setAttendance((prev) => ({
      ...prev,
      [userId]: value,
    }));
    api
      .post('/api/attendance', {
        studentId: userId,
        status: value,
        forDate: selectedDate.toISOString().split('T')[0],
      })
      .then((response) => {
        // Handle success if needed
      })
      .catch((error: AxiosError<BackendError>) => {
        const title = error?.response?.data?.title ?? 'An Error has occurred';
        const description =
          error?.response?.data?.message ?? 'Attendance could not be updated';
        setApiError(description);
        toast({
          title,
          description,
          icon: (
            <CircleAlert
              className="text-white dark:text-slate-900"
              fill="red"
            />
          ),
        });
      });
  };

  const exportAttendance = () => {
    const csvContent = [
      ['Name', 'Attendance Status'],
      ...filteredUsers.map((user) => [
        user.name,
        attendance[user.id] || user.attendanceStatus || 'Not set',
      ]),
    ]
      .map((row) => row.join(','))
      .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      const currentDate = selectedDate.toISOString().split('T')[0];
      link.setAttribute('href', url);
      link.setAttribute('download', `attendance_${currentDate}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <>
      <HeaderNav
        title="All Students"
        showDate
        date={selectedDate}
        onDateChange={(date) => {
          if (date) {
            setSelectedDate(date);
            console.log('Selected date:', date.toISOString());
          }
        }}
      />
      <div className="container mx-auto px-4 py-8">
        {error && (
          <div
            className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4 rounded-r-lg"
            role="alert"
          >
            <p className="font-bold">Error</p>
            <p>{(error as any).message}</p>
            <p>
              Please try{' '}
              <a href="/" className="underline">
                logging in
              </a>{' '}
              again.
            </p>
          </div>
        )}
        {apiError && (
          <div
            className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4 rounded-r-lg"
            role="alert"
          >
            <p className="font-bold">Something went wrong</p>
            <p>{apiError}</p>
          </div>
        )}

        <Card className="w-full mx-auto border-none shadow-none">
          <CardContent className="p-0">
            {error == null && (
              <div className="flex justify-between items-center mb-6">
                <div className="flex-1">
                  <Input
                    type="text"
                    placeholder="Search Students"
                    icon={<Search className="w-5 h-5 opacity-50" />}
                    onChange={filterItems}
                  />
                </div>
                <Button
                  onClick={exportAttendance}
                  className="hidden lg:flex items-center ml-4 py-6 rounded-xl"
                >
                  <Download className="w-4 h-4 mr-2" />
                  Export CSV
                </Button>
              </div>
            )}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {loading
                ? Array.from({ length: 15 }).map((_, index) => (
                    <Card key={index} className="overflow-hidden rounded-3xl">
                      <CardContent className="p-4 flex items-center space-x-4">
                        <Skeleton className="h-12 w-12 rounded-full" />
                        <div className="flex-1">
                          <Skeleton className="h-4 w-3/4 mb-2" />
                          <Skeleton className="h-4 w-1/2" />
                        </div>
                      </CardContent>
                    </Card>
                  ))
                : filteredUsers.map((user) => {
                    const userId = `${user.id}`;
                    return (
                      <Card
                        key={user.id}
                        className="overflow-hidden rounded-3xl"
                      >
                        <CardContent className="p-4 flex items-center space-x-5">
                          <Avatar className="h-12 w-12">
                            <AvatarImage src={user.avatar} alt={user.name} />
                            <AvatarFallback>
                              <UserIcon className="w-6 h-6 text-gray-800" />
                            </AvatarFallback>
                          </Avatar>
                          <div className="flex-1">
                            <Link
                              to={`/student/${user.id}`}
                              state={{ user }}
                              className="font-medium text-lg hover:underline"
                            >
                              {user.name}
                            </Link>
                            <Select
                              value={
                                attendance[userId] ||
                                user.attendanceStatus ||
                                ''
                              }
                              onValueChange={(value) =>
                                handleAttendanceChange(+user.id, value)
                              }
                            >
                              <SelectTrigger
                                className={`mt-2 w-full max-w-[150px] font-medium border rounded-xl px-4 py-2 ${
                                  (attendance[userId] ||
                                    user.attendanceStatus) === 'present'
                                    ? 'bg-green-100 text-green-700 border-2 border-green-300 dark:bg-green-900 dark:text-green-100 dark:border-green-800 dark:hover:bg-green-800'
                                    : (attendance[userId] ||
                                          user.attendanceStatus) === 'late'
                                      ? 'bg-yellow-100 text-yellow-700 border-2 border-yellow-300 dark:bg-yellow-900 dark:text-yellow-100 dark:border-yellow-800 dark:hover:bg-yellow-800'
                                      : (attendance[userId] ||
                                            user.attendanceStatus) === 'absent'
                                        ? 'bg-red-100 text-red-700 border-2 border-red-300 dark:bg-red-900 dark:text-red-100 dark:border-red-800 dark:hover:bg-red-800'
                                        : 'bg-gray-50 text-gray-700 border-gray-200 dark:bg-gray-800 dark:text-gray-100 dark:border-gray-700 dark:hover:bg-gray-700'
                                }`}
                              >
                                <SelectValue placeholder="Attendance" />
                              </SelectTrigger>
                              <SelectContent className="rounded-xl border-none shadow-lg">
                                <SelectGroup>
                                  <SelectItem
                                    value="present"
                                    className="rounded-lg my-1 hover:bg-green-50 dark:hover:bg-green-900"
                                  >
                                    <div className="flex items-center">
                                      <CircleCheck className="mr-2 h-4 w-4 text-green-600" />
                                      <span>Present</span>
                                    </div>
                                  </SelectItem>
                                  <SelectItem
                                    value="late"
                                    className="rounded-lg my-1 hover:bg-yellow-50 dark:hover:bg-yellow-900"
                                  >
                                    <div className="flex items-center">
                                      <CircleAlert className="mr-2 h-4 w-4 text-yellow-600" />
                                      <span>Late</span>
                                    </div>
                                  </SelectItem>
                                  <SelectItem
                                    value="absent"
                                    className="rounded-lg my-1 hover:bg-red-50 dark:hover:bg-red-900"
                                  >
                                    <div className="flex items-center">
                                      <CircleX className="mr-2 h-4 w-4 text-red-600" />
                                      <span>Absent</span>
                                    </div>
                                  </SelectItem>
                                </SelectGroup>
                              </SelectContent>
                            </Select>
                          </div>
                        </CardContent>
                      </Card>
                    );
                  })}
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
}

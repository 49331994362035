'use client';

import React from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from './ui/accordion';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Label } from './ui/label';
import { Switch } from './ui/switch';
import { Card, CardHeader, CardContent } from './ui/card';
import { CircleCheck, Search } from 'lucide-react';
import { Surah, surahs } from '../utils/surahUtils';
import { AssignmentMutationMode } from '../types/common';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderNav from './HeaderNav';

interface LocationState {
  user: {
    id: string;
    name: string;
  };
  type: string;
}

interface VerseRange {
  startVerse: number | null;
  endVerse: number | null;
}

interface Verses {
  [key: number]: VerseRange;
}

interface InputValues {
  [key: number]: {
    startVerse: string;
    endVerse: string;
  };
}

export default function CreateAssignment() {
  const { state } = useLocation() as { state: LocationState };
  const navigate = useNavigate();

  // State management
  const [filteredSurahs, setFilteredSurahs] = React.useState<Surah[]>(surahs);
  const [entireVerse, setEntireVerse] = React.useState<boolean[]>(
    new Array(surahs.length).fill(false)
  );
  const [verses, setVerses] = React.useState<Verses>({});
  const [inputValues, setInputValues] = React.useState<InputValues>({});

  // Filter surahs based on search term
  const filterItems = (searchTerm: React.ChangeEvent<HTMLInputElement>) => {
    const term = searchTerm.target.value.toLowerCase();
    const filteredItems = surahs.filter((surah: Surah) =>
      surah.name.toLowerCase().includes(term)
    );
    setFilteredSurahs(filteredItems);
  };

  // Handle input change for verse numbers
  const handleInputChange = (
    surahNumber: number,
    type: 'startVerse' | 'endVerse',
    value: string
  ) => {
    setInputValues((prev) => ({
      ...prev,
      [surahNumber]: {
        ...(prev[surahNumber] || { startVerse: '', endVerse: '' }),
        [type]: value,
      },
    }));

    // Only update verses if the value is a valid number
    if (value !== '' && !isNaN(parseInt(value, 10))) {
      handleVerseChange(surahNumber, type, parseInt(value, 10));
    } else {
      // If the input is empty or invalid, remove the verse
      setVerses((prev) => {
        const newVerses = { ...prev };
        if (newVerses[surahNumber]) {
          newVerses[surahNumber] = {
            ...newVerses[surahNumber],
            [type]: null,
          };
          // If both start and end are null, remove the entire entry
          if (
            newVerses[surahNumber].startVerse === null &&
            newVerses[surahNumber].endVerse === null
          ) {
            delete newVerses[surahNumber];
          }
        }
        return newVerses;
      });
    }
  };

  // Handle input blur for validation
  const handleVerseBlur = (
    surahNumber: number,
    type: 'startVerse' | 'endVerse'
  ) => {
    const currentSurah = surahs.find((s) => s.surahNumber === surahNumber);
    if (!currentSurah) return;

    const value = inputValues[surahNumber]?.[type] || '';
    const numValue = parseInt(value, 10);

    if (isNaN(numValue)) {
      // If the input is invalid, clear it
      setInputValues((prev) => ({
        ...prev,
        [surahNumber]: {
          ...(prev[surahNumber] || { startVerse: '', endVerse: '' }),
          [type]: '',
        },
      }));
      return;
    }

    // Validate and update with bounded value
    const boundedValue = Math.max(
      currentSurah.startVerse,
      Math.min(currentSurah.endVerse, numValue)
    );

    handleVerseChange(surahNumber, type, boundedValue);

    // Update input display to show bounded value
    setInputValues((prev) => ({
      ...prev,
      [surahNumber]: {
        ...(prev[surahNumber] || { startVerse: '', endVerse: '' }),
        [type]: boundedValue.toString(),
      },
    }));
  };

  // Handle verse range changes
  const handleVerseChange = (
    surahNumber: number,
    type: 'startVerse' | 'endVerse',
    value: number
  ) => {
    setVerses((prev) => {
      const currentSurah = surahs.find((s) => s.surahNumber === surahNumber);
      if (!currentSurah) return prev;

      const currentVerses = prev[surahNumber] || {
        startVerse: null,
        endVerse: null,
      };

      const boundedValue = Math.max(
        currentSurah.startVerse,
        Math.min(currentSurah.endVerse, value)
      );

      const updatedVerses = {
        ...currentVerses,
        [type]: boundedValue,
      };

      if (type === 'startVerse' && updatedVerses.endVerse !== null) {
        updatedVerses.startVerse = Math.min(
          boundedValue,
          updatedVerses.endVerse
        );
      } else if (type === 'endVerse' && updatedVerses.startVerse !== null) {
        updatedVerses.endVerse = Math.max(
          boundedValue,
          updatedVerses.startVerse
        );
      }

      return {
        ...prev,
        [surahNumber]: updatedVerses,
      };
    });

    // Update inputValues to reflect the change
    setInputValues((prev) => ({
      ...prev,
      [surahNumber]: {
        ...(prev[surahNumber] || { startVerse: '', endVerse: '' }),
        [type]: value.toString(),
      },
    }));
  };

  // Clear verse selection
  const handleClearVerse = (surahNumber: number) => {
    setVerses((prev) => {
      const newVerses = { ...prev };
      delete newVerses[surahNumber];
      return newVerses;
    });

    setEntireVerse((prev) => {
      const newEntireVerse = [...prev];
      newEntireVerse[surahNumber - 1] = false;
      return newEntireVerse;
    });

    setInputValues((prev) => {
      const newInputValues = { ...prev };
      delete newInputValues[surahNumber];
      return newInputValues;
    });
  };

  // Handle entire surah selection
  const handleEntireSurahToggle = (
    surahNumber: number,
    checked: boolean,
    surah: Surah
  ) => {
    setEntireVerse((prev) => {
      const newEntireVerse = [...prev];
      newEntireVerse[surahNumber - 1] = checked;
      return newEntireVerse;
    });

    if (checked) {
      setVerses((prev) => ({
        ...prev,
        [surahNumber]: {
          startVerse: surah.startVerse,
          endVerse: surah.endVerse,
        },
      }));

      setInputValues((prev) => ({
        ...prev,
        [surahNumber]: {
          startVerse: surah.startVerse.toString(),
          endVerse: surah.endVerse.toString(),
        },
      }));
    } else {
      handleClearVerse(surahNumber);
    }
  };

  // Navigate to review page
  const handleConfirmLesson = () => {
    const selectedData = {
      verses,
      mode: AssignmentMutationMode.create,
    };

    navigate(`/student/${state.user?.id}/review-assignment`, {
      state: {
        assignment: {
          studentId: state.user.id,
          type: state.type,
        },
        ...selectedData,
        user: state.user,
        mode: AssignmentMutationMode.create,
      },
      replace: true,
    });
  };

  return (
    <>
      <HeaderNav title={`Add New Lesson for ${state.user.name}`} />
      <Card className="w-full pb-24 mx-auto border-none shadow-none md:w-4/5 xl:w-3/5">
        <CardContent className="p-0">
          <CardHeader className="px-4">
            <p>Select a surah and the required verses</p>
            <div className="my-4">
              <Input
                type="text"
                placeholder="Search Surahs"
                onChange={filterItems}
                icon={<Search className="w-4 h-4 opacity-50" />}
              />
            </div>
          </CardHeader>

          <Accordion type="multiple" className="mb-10">
            {filteredSurahs.map((surah: Surah) => (
              <AccordionItem
                key={surah.surahNumber}
                value={`${surah.surahNumber}`}
              >
                <AccordionTrigger className="px-5">
                  <div className="flex justify-between items-center w-full">
                    <span className="font-bold flex items-center gap-1">
                      {(entireVerse[surah.surahNumber - 1] ||
                        (verses[surah.surahNumber]?.startVerse &&
                          verses[surah.surahNumber]?.endVerse)) && (
                        <CircleCheck
                          className="w-5 h-5 text-white"
                          fill="#006766"
                        />
                      )}
                      <span
                        className={
                          entireVerse[surah.surahNumber - 1] ||
                          (verses[surah.surahNumber]?.startVerse &&
                            verses[surah.surahNumber]?.endVerse)
                            ? 'text-primary'
                            : ''
                        }
                      >
                        {surah.name}
                      </span>
                    </span>
                    <span className="text-gray-500 font-normal">
                      {surah.endVerse - surah.startVerse + 1} Verses
                    </span>
                  </div>
                </AccordionTrigger>
                <AccordionContent className="px-5">
                  <div className="flex align-items-center gap-7 mt-4">
                    <div className="w-full">
                      <Label>Starting Verse</Label>
                      <Input
                        type="text"
                        placeholder={`${surah.startVerse}`}
                        value={inputValues[surah.surahNumber]?.startVerse || ''}
                        onChange={(e) =>
                          handleInputChange(
                            surah.surahNumber,
                            'startVerse',
                            e.target.value
                          )
                        }
                        onBlur={() =>
                          handleVerseBlur(surah.surahNumber, 'startVerse')
                        }
                        disabled={entireVerse[surah.surahNumber - 1]}
                        className="mt-2"
                      />
                    </div>
                    <div className="w-full">
                      <Label>Ending Verse</Label>
                      <Input
                        type="text"
                        placeholder={`${surah.endVerse}`}
                        value={inputValues[surah.surahNumber]?.endVerse || ''}
                        onChange={(e) =>
                          handleInputChange(
                            surah.surahNumber,
                            'endVerse',
                            e.target.value
                          )
                        }
                        onBlur={() =>
                          handleVerseBlur(surah.surahNumber, 'endVerse')
                        }
                        disabled={entireVerse[surah.surahNumber - 1]}
                        className="mt-2"
                      />
                    </div>
                  </div>
                  <div className="mt-4 flex justify-between items-center">
                    <div className="flex items-center">
                      <Switch
                        id={`entire-verse-${surah.surahNumber}`}
                        checked={entireVerse[surah.surahNumber - 1] || false}
                        onCheckedChange={(checked) =>
                          handleEntireSurahToggle(
                            surah.surahNumber,
                            checked,
                            surah
                          )
                        }
                      />
                      <Label
                        htmlFor={`entire-verse-${surah.surahNumber}`}
                        className={`ml-2 ${
                          entireVerse[surah.surahNumber - 1]
                            ? 'text-primary'
                            : ''
                        }`}
                      >
                        Entire Surah
                      </Label>
                    </div>
                    <Button
                      variant="link"
                      className="text-sm p-0 underline font-bold"
                      onClick={() => handleClearVerse(surah.surahNumber)}
                    >
                      Clear
                    </Button>
                  </div>
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </CardContent>
      </Card>
      <div className="bg-white fixed bottom-0 w-full py-3 px-4 border-t border-[#C5C5C5] flex justify-end">
        <Button
          variant="ghost"
          onClick={() => navigate(-1)}
          className="mr-2 rounded-sm font-bold text-primary"
        >
          Cancel
        </Button>
        <Button
          variant="default"
          onClick={handleConfirmLesson}
          className="rounded-sm font-bold"
          disabled={Object.keys(verses).length === 0}
        >
          Review Lesson
        </Button>
      </div>
    </>
  );
}

import React from 'react';
import api from '../services/axios';
import { toast } from './useToast';
import { CircleAlert, CircleCheck } from 'lucide-react';
import { Assignment, AssignmentMutationMode, Section } from '../types/common';
import { useNavigate } from 'react-router-dom';

export const useMutateAssignment = () => {
  const [assignment, setAssignment] = React.useState<Assignment>();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<any>(null);
  const navigate = useNavigate();

  const mutateAssignment = React.useCallback(
    async (
      studentId: number,
      requestBody: { assignment: Partial<Assignment>; sections: Section[] },
      mode: AssignmentMutationMode
    ) => {
      setLoading(true);
      try {
        const response =
          mode === AssignmentMutationMode.create
            ? await api.post('/api/assignment/full', requestBody)
            : await api.put(
                `/api/assignment/${requestBody.assignment.id}`,
                requestBody
              );
        toast({
          description: `Lesson was successfully ${mode}d`,
          icon: (
            <CircleCheck
              className="text-white dark:text-slate-900"
              fill="#006766"
            />
          ),
        });
        setAssignment(response.data);
        setError(null);
        navigate(`/student/${studentId}`, {
          state: { user: { id: studentId } },
          replace: true,
        });
      } catch (error: any) {
        console.error({ error });
        const title = error?.response?.data?.title ?? 'An Error has occurred';
        const description =
          error?.response?.data?.message ?? 'Lesson could not be created';
        toast({
          title,
          description,
          icon: (
            <CircleAlert
              className="text-white dark:text-slate-900"
              fill="red"
            />
          ),
        });
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [navigate]
  );

  return {
    assignment,
    loading,
    mutateAssignment,
    error,
  };
};

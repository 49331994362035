import React from 'react';
import { Button } from './ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from './ui/dialog';
import { Label } from './ui/label';
import { Switch } from './ui/switch';
import { ScrollArea } from './ui/scroll-area';
import { Badge } from './ui/badge';
import { surahNameMap } from '../utils/surahUtils';
import { Input } from './ui/input';
import { Section } from '../types/common';

interface StatusModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  sections: Array<Section & { id: number }>;
  selectedSections: {
    [key: number]: { startVerse: number; endVerse: number; selected: boolean };
  };
  onSectionToggle: (surahNumber: number) => void;
  setSelectedSections: (prev: any) => any;
}

const PartialPassSelector = ({
  isOpen,
  onClose,
  onConfirm,
  sections,
  selectedSections,
  onSectionToggle,
  setSelectedSections,
}: StatusModalProps) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[500px] bg-white">
        <DialogHeader>
          <DialogTitle className="text-3xl font-bold text-center text-gray-800">
            Select Sections as <span className="text-teal-600">Pass</span>
          </DialogTitle>
        </DialogHeader>
        <ScrollArea className="h-[400px] pr-4 mt-4 ">
          <div className="space-y-4">
            {sections.map((section) => (
              <div
                key={section.id}
                className="bg-gray-50 rounded-3xl overflow-hidden transition-all duration-300 border"
              >
                <div className="flex items-center justify-between p-4">
                  <div className="flex items-center space-x-3">
                    <Badge
                      variant="outline"
                      className="rounded-full px-3 py-1 text-gray-700 bg-white border w-10 flex justify-center"
                    >
                      {section.surahNumber}
                    </Badge>
                    <div>
                      <Label className="text-lg font-semibold text-gray-800">
                        {surahNameMap[section.surahNumber]}
                      </Label>
                      <p className="text-sm text-gray-600">
                        Verses {section.startVerse}-{section.endVerse}
                      </p>
                    </div>
                  </div>
                  <Switch
                    checked={selectedSections[section.id].selected || false}
                    onCheckedChange={() => onSectionToggle(section.id)}
                    className="data-[state=checked]:bg-teal-600"
                  />
                </div>
                {selectedSections[section.id].selected && (
                  <div className="grid grid-cols-2 gap-4 p-4">
                    <div>
                      <Label className="text-gray-700">Start Verse</Label>
                      <Input
                        className="mt-1"
                        placeholder="1"
                        type="number"
                        value={selectedSections[section.id].startVerse || ''}
                        onChange={(e) => {
                          setSelectedSections((prev: any) => ({
                            ...prev,
                            [section.id]: {
                              ...prev[section.id],
                              startVerse: +e.target.value,
                            },
                          }));
                        }}
                      />
                    </div>
                    <div>
                      <Label className="text-gray-700">End Verse</Label>
                      <Input
                        className="mt-1"
                        placeholder="1"
                        type="number"
                        value={selectedSections[section.id].endVerse || ''}
                        onChange={(e) => {
                          setSelectedSections((prev: any) => ({
                            ...prev,
                            [section.id]: {
                              ...prev[section.id],
                              endVerse: +e.target.value,
                            },
                          }));
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </ScrollArea>
        <DialogFooter className="flex justify-between mt-6">
          <Button
            variant="outline"
            onClick={onClose}
            className="border-gray-300 text-gray-700 rounded-xl hover:bg-gray-100 hover:text-gray-900 transition-colors duration-200"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              onConfirm();
              onClose();
            }}
            className="bg-teal-600 hover:bg-teal-700 rounded-xl text-white transition-colors duration-200"
          >
            Confirm Selection
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default PartialPassSelector;

import { Link, useLocation, matchPath } from 'react-router-dom';
import { FolderSearch, LogOut, NotepadText } from 'lucide-react';
import { pathHash } from '../lib/utils';
import { Button } from './ui/button';
import { useAuth } from '../contexts/AuthContext';

const GeneralFooter = ({ pathHash }: { pathHash: string }) => {
  const location = useLocation();
  const { pathname } = location;
  const { logout, isAtLeastTeacher, user } = useAuth();

  const match = matchPath({ path: '/student/:id/*' }, pathname);
  const studentId = isAtLeastTeacher ? match?.params?.id : user?.id;

  return (
    <footer className="bg-white fixed bottom-0 w-full py-3 border-t border-[#C5C5C5]">
      <nav className="flex items-center justify-between max-w-7xl mx-auto px-8 py-2">
        <div className="flex flex-col items-center justify-center">
          {studentId && (
            <Link
              to={`/student/${studentId}`}
              replace={true}
              className={`text-center flex flex-col items-center ${
                pathHash === 'student' ? 'text-primary' : 'text-[#444444CC]'
              }`}
            >
              <div className="leading-none">
                <NotepadText className="h-6 w-6" />
              </div>
              <div className="text-xs font-bold mt-1">Assignments</div>
            </Link>
          )}
        </div>
        <div className="flex flex-col items-center justify-center">
          {studentId && (
            <Link
              to="/resources"
              className={`text-center flex flex-col items-center ${
                pathHash === 'resources' ? 'text-primary' : 'text-[#444444CC]'
              }`}
            >
              <div className="leading-none">
                <FolderSearch className="h-6 w-6" />
              </div>
              <div className="text-xs font-bold mt-1">Resources</div>
            </Link>
          )}
        </div>
        <div className="flex flex-col items-center justify-center">
          <Button
            variant="link"
            className={`text-center flex flex-col items-center text-[#444444CC]`}
            onClick={logout}
          >
            <div className="leading-none">
              <LogOut className="h-6 w-6" />
            </div>
            <div className="text-xs font-bold mt-1">Logout</div>
          </Button>
        </div>
      </nav>
    </footer>
  );
};

export function FooterNav() {
  const location = useLocation();
  const { pathname } = location;
  const path = pathHash(pathname);

  const footerPaths = [
    'students',
    'editassignment',
    'student',
    'dashboard',
    'studentattendance',
    'resources',
  ];

  if (!footerPaths.includes(path)) {
    return null;
  }

  return <GeneralFooter pathHash={path} />;
}

'use client';

import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { ArrowLeft, Calendar as CalendarIcon } from 'lucide-react';
import { cn } from '../lib/utils';
import { Button } from './ui/button';
import { Calendar } from './ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover';

export default function Component({
  title = 'Hifz Tracker',
  showDate = false,
  date,
  onDateChange,
}: {
  title: string | React.ReactElement;
  showDate?: boolean;
  date?: Date;
  onDateChange?: (date: Date | undefined) => void;
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const shouldShowBackArrow = ![
    '/students',
    '/create-assignment',
    '/dashboard',
  ].includes(location.pathname);

  const handleDateChange = (newDate: Date | undefined) => {
    if (newDate) {
      // Create a new Date object with the selected date's year, month, and day
      // Set the time to noon UTC to avoid any timezone issues
      const adjustedDate = new Date(
        Date.UTC(
          newDate.getFullYear(),
          newDate.getMonth(),
          newDate.getDate(),
          12,
          0,
          0
        )
      );
      onDateChange?.(adjustedDate);
    } else {
      onDateChange?.(undefined);
    }
  };

  return (
    <header className="bg-gradient-to-r from-teal-700 to-teal-800 w-full">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            {shouldShowBackArrow && (
              <Button
                variant="ghost"
                onClick={() => navigate(-1)}
                className="mr-2 text-primary-foreground hover:text-primary-foreground/80 hover:bg-primary-dark/10"
              >
                <ArrowLeft className="h-5 w-5" />
                <span className="sr-only">Go back</span>
              </Button>
            )}
            <h1 className="text-primary-foreground font-bold text-xl tracking-tight">
              {title}
            </h1>
          </div>

          {showDate && (
            <div className="flex items-center text-primary-foreground/90 text-sm font-medium">
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant={'ghost'}
                    className={cn(
                      'justify-start text-left font-medium hover:bg-white/10 hover:text-white rounded-xl',
                      !date && 'text-primary-foreground/50'
                    )}
                  >
                    <CalendarIcon className="mr-2 h-4 w-4" strokeWidth={2.5} />
                    {date ? (
                      format(date, 'MMMM d, yyyy')
                    ) : (
                      <span>Pick a date</span>
                    )}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0 rounded-2xl" align="end">
                  <Calendar
                    mode="single"
                    selected={date}
                    onSelect={handleDateChange}
                    initialFocus
                    toDate={new Date()}
                  />
                </PopoverContent>
              </Popover>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

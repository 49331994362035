'use client';

import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from './ui/accordion';
import { Button } from './ui/button';
import { Card, CardContent } from './ui/card';
import { Input } from './ui/input';
import { Label } from './ui/label';
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover';
import { Switch } from './ui/switch';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/tabs';
import { Textarea } from './ui/textarea';
import { Ban, Bookmark, CircleCheck, PlusCircle, Save } from 'lucide-react';
import HeaderNav from './HeaderNav';
import { useGetAssignment } from '../hooks/useGetAssignment';
import { AssignmentMutationMode } from '../types/common';
import { Surah, surahs } from '../utils/surahUtils';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ui/dialog';
import { ModernAudioRecorderPlayer } from '../component/audio-player';

type MistakeType = 'Major' | 'Minor' | 'Tajweed';

interface MistakeFormData {
  type: MistakeType | null;
  startVerse: number | null;
  endVerse: number | null;
  recordingTime: number;
}

export default function EditAssignment() {
  const [filteredSurahs, setFilteredSurahs] = React.useState<Surah[]>([]);
  const { state } = useLocation();
  const params = useParams();
  const { assignmentId } = params;
  const { assignment, loading: assignmentLoading } = useGetAssignment(
    +assignmentId!
  );
  const [expandedAccordionItems, setExpandedAccordionItems] = React.useState<
    string[]
  >([]);
  const [verses, setVerses] = React.useState<{
    [key: number]: { startVerse: number | null; endVerse: number | null };
  }>({});
  const [initialVerses, setInitialVerses] = React.useState<{
    [key: number]: { startVerse: number | null; endVerse: number | null };
  }>({});
  const [entireVerse, setEntireVerse] = React.useState<{
    [key: number]: boolean;
  }>({});
  const [initialEntireVerse, setInitialEntireVerse] = React.useState<{
    [key: number]: boolean;
  }>({});
  const [inputValues, setInputValues] = React.useState<{
    [key: number]: { startVerse: string; endVerse: string };
  }>({});
  const navigate = useNavigate();
  const [, /*isNoteOpen*/ setIsNoteOpen] = React.useState(false);
  const [isMistakeOpen, setIsMistakeOpen] = React.useState(false);
  const [mistakeFormData, setMistakeFormData] = React.useState<MistakeFormData>(
    {
      type: null,
      startVerse: null,
      endVerse: null,
      recordingTime: 0,
    }
  );

  React.useEffect(() => {
    if (Object.keys(surahs).length > 0) {
      setFilteredSurahs(surahs);
    }
  }, []);

  React.useEffect(() => {
    if (assignment?.Sections && !assignmentLoading) {
      const newVerses: {
        [key: number]: { startVerse: number | null; endVerse: number | null };
      } = {};
      const newEntireVerse: { [key: number]: boolean } = {};
      const expandedItems: string[] = [];
      const newInputValues: {
        [key: number]: { startVerse: string; endVerse: string };
      } = {};

      assignment.Sections.forEach((section) => {
        newVerses[section.surahNumber] = {
          startVerse: section.startVerse,
          endVerse: section.endVerse,
        };
        newEntireVerse[section.surahNumber] = false;
        expandedItems.push(section.surahNumber.toString());
        newInputValues[section.surahNumber] = {
          startVerse: section.startVerse?.toString() || '',
          endVerse: section.endVerse?.toString() || '',
        };
      });

      setVerses(newVerses);
      setInitialVerses({ ...newVerses });
      setEntireVerse(newEntireVerse);
      setInitialEntireVerse({ ...newEntireVerse });
      setExpandedAccordionItems(expandedItems);
      setInputValues(newInputValues);
    }
  }, [assignment, assignmentLoading]);

  const handleInputChange = (
    surahNumber: number,
    type: 'startVerse' | 'endVerse',
    value: string,
    surah: Surah
  ) => {
    setInputValues((prev) => ({
      ...prev,
      [surahNumber]: {
        ...(prev[surahNumber] || { startVerse: '', endVerse: '' }),
        [type]: value,
      },
    }));

    if (value !== '') {
      const numValue = parseInt(value, 10);
      if (!isNaN(numValue)) {
        const boundedValue = Math.max(
          surah.startVerse,
          Math.min(surah.endVerse, numValue)
        );
        setVerses((prev) => ({
          ...prev,
          [surahNumber]: {
            ...prev[surahNumber],
            [type]: boundedValue,
          },
        }));
      }
    } else {
      setVerses((prev) => {
        const newVerses = { ...prev };
        if (newVerses[surahNumber]) {
          newVerses[surahNumber] = {
            ...newVerses[surahNumber],
            [type]: null,
          };
          if (
            newVerses[surahNumber].startVerse === null &&
            newVerses[surahNumber].endVerse === null
          ) {
            delete newVerses[surahNumber];
          }
        }
        return newVerses;
      });
    }
  };

  const handleVerseBlur = (
    surahNumber: number,
    type: 'startVerse' | 'endVerse',
    surah: Surah
  ) => {
    const value = inputValues[surahNumber]?.[type] || '';
    const numValue = parseInt(value, 10);

    if (isNaN(numValue)) {
      setInputValues((prev) => ({
        ...prev,
        [surahNumber]: {
          ...(prev[surahNumber] || { startVerse: '', endVerse: '' }),
          [type]: '',
        },
      }));
      setVerses((prev) => {
        const newVerses = { ...prev };
        if (newVerses[surahNumber]) {
          newVerses[surahNumber] = {
            ...newVerses[surahNumber],
            [type]: null,
          };
          if (
            newVerses[surahNumber].startVerse === null &&
            newVerses[surahNumber].endVerse === null
          ) {
            delete newVerses[surahNumber];
          }
        }
        return newVerses;
      });
    } else {
      const boundedValue = Math.max(
        surah.startVerse,
        Math.min(surah.endVerse, numValue)
      );
      setInputValues((prev) => ({
        ...prev,
        [surahNumber]: {
          ...(prev[surahNumber] || { startVerse: '', endVerse: '' }),
          [type]: boundedValue.toString(),
        },
      }));
      setVerses((prev) => ({
        ...prev,
        [surahNumber]: {
          ...prev[surahNumber],
          [type]: boundedValue,
        },
      }));
    }
  };

  const handleClearVerse = (surahNumber: number) => {
    setVerses((prev) => {
      const newVerses = { ...prev };
      delete newVerses[surahNumber];
      return newVerses;
    });

    setEntireVerse((prev) => {
      const newEntireVerse = { ...prev };
      delete newEntireVerse[surahNumber];
      return newEntireVerse;
    });

    setInputValues((prev) => {
      const newInputValues = { ...prev };
      delete newInputValues[surahNumber];
      return newInputValues;
    });

    setExpandedAccordionItems((prev) =>
      prev.filter((item) => item !== surahNumber.toString())
    );
  };

  const handleConfirmLesson = () => {
    const selectedData = {
      verses,
      mode: AssignmentMutationMode.update,
      assignmentId,
    };
    navigate(`/student/${state.user?.id}/review-assignment`, {
      state: {
        assignment: { id: assignmentId, Sections: { ...selectedData } },
        user: state.user,
        ...selectedData,
        mode: AssignmentMutationMode.update,
      },
      replace: true,
    });
  };

  const handleSaveNote = () => {
    // Implement note saving logic here
    setIsNoteOpen(false);
  };

  const handleVerseChange = (surahNumber: number) => {
    const verseData = verses[surahNumber];
    const hasVerses =
      verseData &&
      (verseData.startVerse !== null || verseData.endVerse !== null);
    return hasVerses || entireVerse[surahNumber];
  };

  const hasChanges = (): boolean => {
    const surahNumbers = Array.from(
      new Set([
        ...Object.keys(verses).map(Number),
        ...Object.keys(initialVerses).map(Number),
      ])
    );

    for (const surahNumber of surahNumbers) {
      const current = verses[surahNumber] || {
        startVerse: null,
        endVerse: null,
      };
      const initial = initialVerses[surahNumber] || {
        startVerse: null,
        endVerse: null,
      };
      const entireVerseChanged =
        entireVerse[surahNumber] !== initialEntireVerse[surahNumber];

      if (
        entireVerseChanged ||
        current.startVerse !== initial.startVerse ||
        current.endVerse !== initial.endVerse
      ) {
        return true;
      }
    }

    return false;
  };

  const handleMistakeTypeSelect = (type: MistakeType) => {
    setMistakeFormData((prev) => ({
      ...prev,
      type,
    }));
  };

  const handleMistakeSubmit = () => {
    // Implement mistake submission logic here
    setIsMistakeOpen(false);
    setMistakeFormData({
      type: null,
      startVerse: null,
      endVerse: null,
      recordingTime: 0,
    });
  };

  const NoteFormContent = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement> & { surahName: string; verses: string }
  >(({ surahName, verses, ...props }, ref) => (
    <div {...props} ref={ref}>
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="note">
            Note for lesson {surahName}, {verses}
          </Label>
          <Textarea
            id="note"
            placeholder="Write your note here..."
            className="min-h-[200px] w-full rounded-xl border p-4 resize-none"
            rows={5}
          />
        </div>
      </div>
      <div className="flex justify-end mt-4">
        <Button onClick={handleSaveNote} className="rounded-xl">
          <Save className="mr-2 h-4 w-4" />
          Save
        </Button>
      </div>
    </div>
  ));
  NoteFormContent.displayName = 'NoteFormContent';

  const MistakeFormContent = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement>
  >((props, ref) => (
    <div {...props} ref={ref}>
      <div className="space-y-6">
        <div>
          <div className="flex gap-4 mb-6">
            <Button
              variant={mistakeFormData.type === 'Major' ? 'default' : 'outline'}
              onClick={() => handleMistakeTypeSelect('Major')}
              className="flex-1 rounded-xl"
            >
              Major
            </Button>
            <Button
              variant={mistakeFormData.type === 'Minor' ? 'default' : 'outline'}
              onClick={() => handleMistakeTypeSelect('Minor')}
              className="flex-1 rounded-xl"
            >
              Minor
            </Button>
            <Button
              variant={
                mistakeFormData.type === 'Tajweed' ? 'default' : 'outline'
              }
              onClick={() => handleMistakeTypeSelect('Tajweed')}
              className="flex-1 rounded-xl"
            >
              Tajweed
            </Button>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <Label>Starting Verse</Label>
            <Input
              className="mt-1"
              placeholder="1"
              type="number"
              value={mistakeFormData.startVerse || ''}
              onChange={(e) =>
                setMistakeFormData((prev) => ({
                  ...prev,
                  startVerse: parseInt(e.target.value) || null,
                }))
              }
            />
          </div>
          <div>
            <Label>Ending Verse</Label>
            <Input
              className="mt-1"
              placeholder="7"
              type="number"
              value={mistakeFormData.endVerse || ''}
              onChange={(e) =>
                setMistakeFormData((prev) => ({
                  ...prev,
                  endVerse: parseInt(e.target.value) || null,
                }))
              }
            />
          </div>
        </div>
        <div>
          <ModernAudioRecorderPlayer />
        </div>
      </div>
      <div className="flex justify-end space-x-2 mt-4">
        <Button
          variant="outline"
          onClick={() => setIsMistakeOpen(false)}
          className="rounded-xl"
        >
          Cancel
        </Button>
        <Button
          onClick={handleMistakeSubmit}
          disabled={!mistakeFormData.type}
          className="rounded-xl"
        >
          Submit
        </Button>
      </div>
    </div>
  ));
  MistakeFormContent.displayName = 'MistakeFormContent';

  return (
    <>
      <HeaderNav title="Edit Lesson" />
      <Card className="w-full pb-24 mx-auto border-none shadow-none md:w-4/5 xl:w-3/5">
        <CardContent className="p-0">
          <Tabs defaultValue="lesson-details" className="w-full mt-4">
            <div className="px-2">
              <TabsList className="grid w-full grid-cols-2 p-1 bg-muted rounded-lg">
                <TabsTrigger
                  value="lesson-details"
                  className="rounded-md px-3 py-1.5 text-sm font-medium transition-all data-[state=active]:bg-background relative"
                >
                  <div className="flex items-center justify-center">
                    <Bookmark className="mr-2 h-4 w-4" />
                    Lesson Details
                  </div>
                </TabsTrigger>
                <TabsTrigger
                  disabled={hasChanges()}
                  value="mistakes"
                  className="rounded-md px-3 py-1.5 text-sm font-medium transition-all data-[state=active]:bg-background relative"
                >
                  <div className="flex items-center justify-center">
                    <Ban className="mr-2 h-4 w-4" />
                    Mistakes
                  </div>
                </TabsTrigger>
              </TabsList>
            </div>

            <TabsContent value="lesson-details">
              <Accordion
                value={expandedAccordionItems}
                onValueChange={setExpandedAccordionItems}
                type="multiple"
                className="mb-10"
              >
                {filteredSurahs.map((d: Surah) => (
                  <AccordionItem key={d.surahNumber} value={`${d.surahNumber}`}>
                    <AccordionTrigger className="px-5">
                      <div className="flex justify-between items-center w-full">
                        <span className="font-bold flex items-center">
                          {handleVerseChange(d.surahNumber) && (
                            <CircleCheck
                              className="w-5 h-5 text-white"
                              fill="#006766"
                            />
                          )}
                          <span
                            className={
                              handleVerseChange(d.surahNumber)
                                ? 'text-primary'
                                : ''
                            }
                          >
                            {d.name}
                          </span>
                        </span>
                        <span className="text-gray-500 font-normal">
                          {d.endVerse - d.startVerse + 1} Verses
                        </span>
                      </div>
                    </AccordionTrigger>
                    <AccordionContent className="px-5">
                      <div className="flex align-items-center gap-7 mt-4">
                        <div className="w-full">
                          <Label>Starting Verse</Label>
                          <Input
                            type="text"
                            placeholder={`${d.startVerse}`}
                            disabled={entireVerse[d.surahNumber]}
                            value={inputValues[d.surahNumber]?.startVerse || ''}
                            onChange={(e) =>
                              handleInputChange(
                                d.surahNumber,
                                'startVerse',
                                e.target.value,
                                d
                              )
                            }
                            onBlur={() =>
                              handleVerseBlur(d.surahNumber, 'startVerse', d)
                            }
                            className="mt-2"
                          />
                        </div>
                        <div className="w-full">
                          <Label>Ending Verse</Label>
                          <Input
                            type="text"
                            placeholder={`${d.endVerse}`}
                            disabled={entireVerse[d.surahNumber]}
                            value={inputValues[d.surahNumber]?.endVerse || ''}
                            onChange={(e) =>
                              handleInputChange(
                                d.surahNumber,
                                'endVerse',
                                e.target.value,
                                d
                              )
                            }
                            onBlur={() =>
                              handleVerseBlur(d.surahNumber, 'endVerse', d)
                            }
                            className="mt-2"
                          />
                        </div>
                      </div>
                      <div className="mt-4 flex justify-between items-center">
                        <div className="flex items-center">
                          <Switch
                            id={`entire-verse-${d.surahNumber}`}
                            checked={entireVerse[d.surahNumber] || false}
                            onCheckedChange={(checked) => {
                              setEntireVerse((prev) => ({
                                ...prev,
                                [d.surahNumber]: checked,
                              }));
                              setVerses((prev) => ({
                                ...prev,
                                [d.surahNumber]: {
                                  startVerse: checked ? d.startVerse : null,
                                  endVerse: checked ? d.endVerse : null,
                                },
                              }));
                              setInputValues((prev) => ({
                                ...prev,
                                [d.surahNumber]: {
                                  startVerse: checked
                                    ? d.startVerse.toString()
                                    : '',
                                  endVerse: checked
                                    ? d.endVerse.toString()
                                    : '',
                                },
                              }));
                            }}
                          />
                          <Label
                            htmlFor={`entire-verse-${d.surahNumber}`}
                            className={`ml-2 ${
                              entireVerse[d.surahNumber] ? 'text-primary' : ''
                            }`}
                          >
                            Entire Surah
                          </Label>
                        </div>
                        <div className="space-x-3">
                          <Button
                            variant="link"
                            className="text-sm p-0 underline font-bold"
                            onClick={() => handleClearVerse(d.surahNumber)}
                          >
                            Clear
                          </Button>
                          <Popover>
                            <PopoverTrigger asChild>
                              <Button
                                variant="link"
                                className="text-sm p-0 underline font-bold"
                              >
                                Add Note
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-full min-w-[350px] rounded-3xl p-6">
                              <NoteFormContent
                                surahName={d.name}
                                verses={`${
                                  verses[d.surahNumber]?.startVerse ||
                                  d.startVerse
                                }-${
                                  verses[d.surahNumber]?.endVerse || d.endVerse
                                }`}
                              />
                            </PopoverContent>
                          </Popover>
                        </div>
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                ))}
              </Accordion>
            </TabsContent>

            <TabsContent value="mistakes" className="p-6">
              <Card className="rounded-3xl">
                <CardContent className="pt-6">
                  <div className="text-center space-y-4">
                    <h3 className="text-lg font-semibold text-primary">
                      Record Mistakes
                    </h3>
                    <p className="text-muted-foreground">
                      Keep track of errors and improve your learning
                    </p>
                    <Dialog
                      open={isMistakeOpen}
                      onOpenChange={setIsMistakeOpen}
                    >
                      <DialogTrigger asChild>
                        <Button
                          className="w-full max-w-md rounded-xl"
                          size="lg"
                        >
                          <PlusCircle className="mr-2 h-5 w-5" />
                          Add New Mistake
                        </Button>
                      </DialogTrigger>
                      <DialogContent className="rounded-3xl">
                        <DialogHeader>
                          <DialogTitle>Record Mistake</DialogTitle>
                        </DialogHeader>
                        <MistakeFormContent />
                      </DialogContent>
                    </Dialog>
                  </div>
                </CardContent>
              </Card>
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>
      <div className="bg-white fixed bottom-0 w-full py-3 px-4 border-t border-[#C5C5C5] flex justify-end">
        <Button
          variant="ghost"
          onClick={() => navigate(-1)}
          className="mr-3 rounded-xl"
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirmLesson}
          disabled={!hasChanges()}
          className="rounded-xl"
        >
          Confirm
        </Button>
      </div>
    </>
  );
}

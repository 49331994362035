'use client';

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  CardFooter,
  CardTitle,
} from './ui/card';
import { Button } from './ui/button';
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectItem,
} from './ui/select';
import { Badge } from './ui/badge';
import { Skeleton } from './ui/skeleton';
import { Tabs, TabsList, TabsTrigger } from './ui/tabs';
import {
  PieChart,
  Pie,
  Cell,
  Label,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LabelList,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import {
  Book,
  Clock,
  Bookmark,
  RefreshCw,
  CircleCheck,
  CircleAlert,
  CircleX,
  CircleEllipsis,
  CirclePause,
  Plus,
  Calendar,
} from 'lucide-react';
import { format, startOfMonth, startOfYear } from 'date-fns';

import HeaderNav from './HeaderNav';
import { useGetUser } from '../hooks/useGetUser';
import { useGetAssignments } from '../hooks/useGetAssignments';
import { useGetAggregateAttendance } from '../hooks/useGetAggregateAttendance';
import { useAuth } from '../contexts/AuthContext';
import { surahNameMap } from '../utils/surahUtils';
import {
  Assignment,
  AssignmentType,
  ReviewCounts,
  AttendanceAggregate,
  Section,
} from '../types/common';
import { AxiosError } from 'axios';
import { BackendError } from '../types/error';
import api from '../services/axios';
import { toast } from '../hooks/useToast';
import _ from 'lodash';
import PartialPassSelector from './PartialPassSelector';

interface AssignmentProps {
  assignment: Assignment;
}

export default function Component() {
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const { isAtLeastTeacher } = useAuth();
  const { user, loading: userLoading } = useGetUser(id);
  const {
    assignments,
    loading: assignmentsLoading,
    refetch,
  } = useGetAssignments(id);
  const [assignmentResult, setAssignmentResult] = useState<{
    [key: string]: string;
  }>({});
  const [isStatusModalOpen, setIsStatusModalOpen] = React.useState(false);
  const [selectedSections, setSelectedSections] = React.useState<{
    [key: number]: {
      startVerse: number;
      endVerse: number;
      selected: boolean;
    };
  }>({});
  const [pendingAssignment, setPendingAssignment] =
    React.useState<Assignment | null>(null);
  const [currentTab, setCurrentTab] = useState('monthly');
  const [attendanceSummary, setAttendanceSummary] =
    useState<AttendanceAggregate>({ present: 0, absent: 0, late: 0 });

  const {
    monthlyAttendanceSummary,
    yearlyAttendanceSummary,
    loading: attendanceLoading,
  } = useGetAggregateAttendance(id);

  useEffect(() => {
    const summary =
      currentTab === 'monthly'
        ? monthlyAttendanceSummary
        : yearlyAttendanceSummary;
    if (summary) {
      setAttendanceSummary({
        present: summary.present || 0,
        absent: summary.absent || 0,
        late: summary.late || 0,
      });
    }
  }, [currentTab, monthlyAttendanceSummary, yearlyAttendanceSummary]);

  const assignmentTypeOrder = [
    AssignmentType.new,
    AssignmentType.past,
    AssignmentType.seven_pages,
    AssignmentType.revision,
  ];

  function handleStatusChange(assignment: Assignment, value: string) {
    if (value === 'partial_pass') {
      setPendingAssignment(assignment);
      const initialSections = assignment.Sections?.reduce(
        (acc, section: Section) => {
          acc[section.id ?? 0] = {
            startVerse: section.startVerse,
            endVerse: section.endVerse,
            selected: false,
          };
          return acc;
        },
        {} as {
          [key: number]: {
            startVerse: number;
            endVerse: number;
            selected: boolean;
          };
        }
      );
      setSelectedSections(initialSections || {});
      setIsStatusModalOpen(true);
    } else {
      setAssignmentResult((prev) => ({
        ...prev,
        [assignment.id]: value,
      }));
      api
        .put(`/api/assignment/${assignment.id}/status`, {
          status: value,
        })
        .then(() => {
          refetch();
        })
        .catch((error: AxiosError<BackendError>) => {
          const title = error?.response?.data?.title ?? 'An Error has occurred';
          const description =
            error?.response?.data?.message ?? 'Lesson could not be created';
          toast({
            title,
            description,
            icon: (
              <CircleAlert
                className="text-white dark:text-slate-900"
                fill="red"
              />
            ),
          });
        });
    }
  }

  const handleSectionToggle = (sectionId: number) => {
    setSelectedSections((prev) => ({
      ...prev,
      [sectionId]: { ...prev[sectionId], selected: !prev[sectionId].selected },
    }));
  };
  const handleStatusModalConfirm = () => {
    if (pendingAssignment) {
      console.log({
        selections: Object.keys(selectedSections)
          .filter((e: string) => selectedSections[+e].selected)
          .map((e) => selectedSections[+e]),
      });
      // api
      //   .put(`/api/assignment/${pendingAssignment.id}/status`, {
      //     status: 'partial_pass',
      //     sectionResults,
      //   })
      //   .then(() => {
      //     refetch();
      //     setAssignmentResult((prev) => ({
      //       ...prev,
      //       [pendingAssignment.id]: 'partial_pass',
      //     }));
      //   })
      //   .catch((error: AxiosError<BackendError>) => {
      //     const title = error?.response?.data?.title ?? 'An Error has occurred';
      //     const description =
      //       error?.response?.data?.message ?? 'Status could not be updated';
      //     toast({
      //       title,
      //       description,
      //       icon: (
      //         <CircleAlert
      //           className="text-white dark:text-slate-900"
      //           fill="red"
      //         />
      //       ),
      //     });
      //   })
      //   .finally(() => {
      //     setIsStatusModalOpen(false);
      //     setPendingAssignment(null);
      //     setSelectedSections({});
      //   });
    }
  };

  const getSelectTriggerStyle = (assignment: Assignment) => {
    const status = assignmentResult[assignment.id] ?? assignment.status;
    const styles = {
      draft: 'text-gray-700 border-gray-300',
      partial_pass: 'text-yellow-700 border-yellow-300',
      pass: 'text-green-700 border-green-300',
      fail: 'text-red-700 border-red-300',
      in_progress: 'text-blue-700 border-blue-300',
      pause: 'text-orange-700 border-orange-300',
    };
    return `${styles[status as keyof typeof styles]} border-2`;
  };

  const DropDown = ({
    assignment,
    value,
  }: {
    assignment: Assignment;
    value: string;
  }) => (
    <Select
      value={assignmentResult[assignment.id] ?? value ?? undefined}
      onValueChange={(newValue) => handleStatusChange(assignment, newValue)}
    >
      <SelectTrigger
        className={`p-2 w-full max-w-[150px] font-medium rounded-full ${getSelectTriggerStyle(
          assignment
        )}`}
      >
        <SelectValue placeholder="Not Started" />
      </SelectTrigger>
      <SelectContent className="p-2 min-w-[180px] rounded-2xl">
        <SelectGroup>
          {[
            { value: 'draft', label: 'Not Started', icon: CircleEllipsis },
            {
              value: 'pass',
              label: 'Pass',
              icon: CircleCheck,
              color: 'text-green-500',
            },
            {
              value: 'partial_pass',
              label: 'Partial Pass',
              icon: CircleAlert,
              color: 'text-yellow-500',
            },
            {
              value: 'fail',
              label: 'Fail',
              icon: CircleX,
              color: 'text-red-500',
            },
            {
              value: 'in_progress',
              label: 'In Progress',
              icon: CircleEllipsis,
              color: 'text-blue-500',
            },
            {
              value: 'pause',
              label: 'Pause',
              icon: CirclePause,
              color: 'text-orange-500',
            },
          ].map(({ value, label, icon: Icon, color }) => (
            <SelectItem key={value} value={value} className="rounded-lg my-1">
              <div className="flex items-center">
                <Icon className={`h-4 w-4 mr-2 ${color ?? ''}`} />
                <span>{label}</span>
              </div>
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );

  const getAssignmentTypeIcon = (type: AssignmentType) => {
    const icons = {
      [AssignmentType.new]: Book,
      [AssignmentType.past]: Clock,
      [AssignmentType.seven_pages]: Bookmark,
      [AssignmentType.revision]: RefreshCw,
    };
    const Icon = icons[type];
    return (
      <Icon
        className="inline-block h-5 w-5 text-teal-500 flex-shrink-0 mr-2"
        strokeWidth={2.5}
      />
    );
  };

  const AssignmentCard: React.FC<AssignmentProps> = ({ assignment }) => {
    const navigate = useNavigate();

    const sections = assignment.Sections || [];
    const midpoint = Math.ceil(sections.length / 2);
    const leftColumnSections = sections.slice(0, midpoint);
    const rightColumnSections = sections.slice(midpoint);

    const formatDate = (date: string) => {
      return new Date(date).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
    };

    return (
      <Card className="h-full flex flex-col overflow-hidden rounded-3xl shadow-md shadow-centered mt-3">
        <CardHeader className="border-b py-3 px-4 md:px-7 bg-gray-100">
          <div className="flex justify-between items-center">
            <div>
              <div className="flex items-center font-bold text-lg text-gray-800">
                {_.startCase(_.toLower(assignment.type))} Lesson
              </div>
              {assignment.forDate && (
                <div className="flex items-center text-sm text-gray-600 mt-1">
                  <Calendar className="w-4 h-4 mr-1 text-teal-500" />
                  <span>Due {formatDate(assignment.forDate)}</span>
                </div>
              )}
            </div>
            {isAtLeastTeacher && (
              <DropDown assignment={assignment} value={assignment.status} />
            )}
          </div>
        </CardHeader>
        <CardContent className="py-4 px-4 md:px-6 flex-grow">
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-3 sm:gap-4 md:gap-3 lg:gap-4">
            {[...leftColumnSections, ...rightColumnSections].map(
              (s: any, i: number) => (
                <div key={i} className="bg-gray-50 border p-3 rounded-2xl">
                  <div className="flex justify-between items-center mb-1">
                    <span className="text-gray-800 font-medium">
                      {surahNameMap[s.surahNumber]}
                    </span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-600 font-medium">
                      Verses {s.startVerse}-{s.endVerse}
                    </span>
                  </div>
                </div>
              )
            )}
          </div>
        </CardContent>
        <CardFooter className="mt-auto border-t pt-4">
          <div className="w-full">
            <div className="flex justify-between items-center mb-2">
              <span className="text-gray-600 font-medium">Mistakes</span>
              {isAtLeastTeacher && (
                <Button
                  variant="ghost"
                  size="sm"
                  className="text-primary hover:text-primary-dark transition-colors rounded-xl underline"
                  onClick={() => {
                    if (user) {
                      navigate(`/student/${assignment.id}/edit-assignment`, {
                        state: { user },
                      });
                    }
                  }}
                >
                  Details
                </Button>
              )}
            </div>
            <div className="flex items-center space-x-2">
              <ReviewCountsDisplay
                reviewCounts={assignment.reviewCounts}
                showTajweed={true}
              />
            </div>
          </div>
        </CardFooter>
      </Card>
    );
  };

  const ReviewCountsDisplay = ({
    reviewCounts,
    showTajweed = false,
  }: {
    reviewCounts?: ReviewCounts;
    showTajweed?: boolean;
  }) => {
    const counts = reviewCounts || { minor: 0, major: 0, tajweed: 0, total: 0 };
    return (
      <>
        <Badge
          variant="secondary"
          className="bg-teal-100/70 text-teal-600 hover:bg-teal-100/70"
        >
          {counts.major} Major
        </Badge>
        <Badge
          variant="secondary"
          className="bg-teal-100/70 text-teal-600 hover:bg-teal-100/70"
        >
          {counts.minor} Minor
        </Badge>
        {showTajweed && (
          <Badge
            variant="secondary"
            className="bg-teal-100/70 text-teal-600 hover:bg-teal-100/70"
          >
            {counts.tajweed} Tajweed
          </Badge>
        )}
      </>
    );
  };

  const renderAssignmentSection = (type: AssignmentType) => {
    const assignmentsOfType = assignments.filter(
      (a: Assignment) => a.type === type
    );

    if (isAtLeastTeacher && assignmentsOfType.length === 0) {
      return (
        <Card
          className="h-full min-h-[250px] flex flex-col overflow-hidden rounded-3xl shadow-md mt-3"
          key={`empty-${type}`}
        >
          <CardHeader className="border-b py-6 px-4 md:px-7 bg-gray-100">
            <span className="flex items-center font-bold text-lg text-gray-800">
              {getAssignmentTypeIcon(type)}{' '}
              {type.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase())}{' '}
              Lesson
            </span>
          </CardHeader>
          <CardContent className="p-0 flex-grow flex items-center justify-center">
            <Button
              className="flex items-center justify-center w-full h-full text-primary hover:text-primary-dark hover:bg-gray-50 transition-colors"
              variant="ghost"
              onClick={() =>
                navigate(`/student/${user?.id}/create-assignment`, {
                  state: { user, type },
                })
              }
            >
              <Plus className="h-6 w-6 mr-2" strokeWidth={2} /> Add{' '}
              {type.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase())}{' '}
              Lesson
            </Button>
          </CardContent>
        </Card>
      );
    } else if (!isAtLeastTeacher && assignmentsOfType.length === 0) {
      return <Skeleton className="h-full w-full rounded-xl" />;
    }

    return assignmentsOfType.map((assignment: Assignment) => (
      <AssignmentCard
        key={`${type}-${assignment.id}`}
        assignment={assignment}
      />
    ));
  };

  const AttendanceChart = () => {
    const totalAttendance = Math.max(
      1,
      (attendanceSummary.present || 0) +
        (attendanceSummary.late || 0) +
        (attendanceSummary.absent || 0)
    );

    const presentPercentage = Math.round(
      ((attendanceSummary.present || 0) / totalAttendance) * 100
    );

    const chartData = [
      {
        name: 'Present',
        value: ((attendanceSummary.present || 0) / totalAttendance) * 100,
      },
      {
        name: 'Late',
        value: ((attendanceSummary.late || 0) / totalAttendance) * 100,
      },
      {
        name: 'Absent',
        value: ((attendanceSummary.absent || 0) / totalAttendance) * 100,
      },
    ].filter((item) => item.value > 0);

    const barChartData = [
      { status: 'Present', count: attendanceSummary.present || 0 },
      { status: 'Late', count: attendanceSummary.late || 0 },
      { status: 'Absent', count: attendanceSummary.absent || 0 },
    ].filter((item) => item.count > 0);

    const chartConfig = {
      present: {
        label: 'Present',
        color: '#3ed675',
      },
      late: {
        label: 'Late',
        color: '#f5e46c',
      },
      absent: {
        label: 'Absent',
        color: '#ed5858',
      },
    };

    const currentMonthFirstDate = format(startOfMonth(new Date()), 'MM/dd/yy');
    const today = format(new Date(), 'MM/dd/yy');
    const currentYearFirstDate = format(startOfYear(new Date()), 'MM/dd/yy');

    return (
      <Card className="mt-4 w-full p-0 rounded-3xl overflow-hidden bg-white shadow-lg">
        <CardHeader className="border-b py-4 px-5 md:px-7 lg:px-8 flex flex-row justify-between items-center bg-gray-50">
          <CardTitle className="flex items-center space-x-2 text-xl font-bold text-gray-800">
            <Calendar
              className="h-5 w-5 mr-2 text-teal-500"
              strokeWidth={2.5}
            />{' '}
            Attendance
          </CardTitle>
          <div className="text-sm font-medium text-gray-600">
            {currentTab === 'monthly'
              ? `${currentMonthFirstDate} - ${today}`
              : `${currentYearFirstDate} - ${today}`}
          </div>
        </CardHeader>
        <CardContent className="flex flex-col items-center justify-center p-6 md:p-8 md:py-12">
          <Tabs
            value={currentTab}
            onValueChange={setCurrentTab}
            className="mb-8 lg:mb-14 w-full flex justify-center"
          >
            <TabsList className="w-full space-x-7 bg-transparent">
              <TabsTrigger
                value="monthly"
                className="text-teal-600 rounded-xl font-bold data-[state=active]:bg-teal-100/70 data-[state=active]:text-teal-600 data-[state=active]:shadow-none"
              >
                Monthly
              </TabsTrigger>
              <TabsTrigger
                value="yearly"
                className="text-teal-600 rounded-xl font-bold data-[state=active]:bg-teal-100/70 data-[state=active]:text-teal-600 data-[state=active]:shadow-none"
              >
                Yearly
              </TabsTrigger>
            </TabsList>
          </Tabs>
          <div className="grid grid-cols-1 lg:grid-cols-5 gap-12 xl:gap-20">
            <div className="w-full lg:col-span-2">
              <div className="mx-auto w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl flex justify-center">
                <PieChart
                  width={300}
                  height={300}
                  className="outline-none [&_*]:outline-none"
                >
                  <Pie
                    data={chartData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    innerRadius={90}
                    outerRadius={128}
                    strokeWidth={0}
                    animationDuration={1000}
                    animationBegin={0}
                  >
                    <Label
                      content={({ viewBox }) => {
                        if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                          return (
                            <text
                              x={viewBox.cx}
                              y={viewBox.cy}
                              textAnchor="middle"
                              dominantBaseline="middle"
                            >
                              <tspan
                                x={viewBox.cx}
                                y={viewBox.cy}
                                className="fill-foreground text-2xl font-bold"
                              >
                                {`${presentPercentage}%`}
                              </tspan>
                              <tspan
                                x={viewBox.cx}
                                y={(viewBox.cy || 0) + 24}
                                className="fill-muted-foreground font-medium text-base"
                              >
                                Present
                              </tspan>
                            </text>
                          );
                        }
                        return null;
                      }}
                    />
                    {chartData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={
                          chartConfig[
                            entry.name.toLowerCase() as keyof typeof chartConfig
                          ].color
                        }
                      />
                    ))}
                  </Pie>
                  <Tooltip
                    content={({ active, payload }) => {
                      if (active && payload && payload.length) {
                        return (
                          <div className="bg-white p-3 rounded-[15px] shadow-md">
                            {payload.map((entry, index) => (
                              <div
                                key={index}
                                className="flex items-center mb-2 last:mb-0"
                              >
                                <div
                                  className="w-3 h-3 rounded-full mr-2"
                                  style={{
                                    backgroundColor:
                                      chartConfig[
                                        String(
                                          entry.name
                                        ).toLowerCase() as keyof typeof chartConfig
                                      ].color,
                                  }}
                                />
                                <span className="font-medium text-gray-700">
                                  {entry.name}
                                </span>
                                <span className="font-medium text-gray-500 ml-2">
                                  {typeof entry.value === 'number'
                                    ? `${entry.value.toFixed(2)}%`
                                    : entry.value}
                                </span>
                              </div>
                            ))}
                          </div>
                        );
                      }
                      return null;
                    }}
                  />
                </PieChart>
              </div>
            </div>
            <div className="w-full h-[300px] sm:h-[250px] lg:h-[300px] lg:col-span-3">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={300}
                  height={200}
                  data={barChartData}
                  layout="horizontal"
                  margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="2 2" vertical={false} />
                  <XAxis
                    dataKey="status"
                    type="category"
                    tickLine={false}
                    axisLine={false}
                    className="font-medium text-sm"
                  />
                  <YAxis type="number" hide />
                  <Bar dataKey="count" radius={[7, 7, 0, 0]} barSize={50}>
                    {barChartData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={
                          chartConfig[
                            entry.status.toLowerCase() as keyof typeof chartConfig
                          ].color
                        }
                      />
                    ))}
                    <LabelList
                      dataKey="count"
                      position="right"
                      offset={10}
                      className="font-medium fill-foreground"
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          {attendanceLoading ? (
            <div className="w-full space-y-4">
              <Skeleton className="h-64 w-full rounded-xl" />
              <Skeleton className="h-40 w-full rounded-xl" />
            </div>
          ) : chartData.length === 0 && barChartData.length === 0 ? (
            <div className="text-center py-10 text-gray-500">
              <p className="text-lg font-medium">
                No attendance data available for this period
              </p>
              <p className="text-sm mt-2">Check back later for updates</p>
            </div>
          ) : null}
        </CardContent>
      </Card>
    );
  };

  if (userLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Skeleton className="h-12 w-12 rounded-full" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <HeaderNav title={<span className="font-bold">{user?.name}</span>} />
      <div className="p-4 pt-6 pb-24">
        <div className="grid grid-cols-1 gap-6 w-full mx-auto md:max-w-7xl">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {!assignmentsLoading &&
              assignmentTypeOrder.map((type: AssignmentType) => (
                <React.Fragment key={type}>
                  {renderAssignmentSection(type)}
                </React.Fragment>
              ))}
          </div>
          {pendingAssignment && (
            <PartialPassSelector
              isOpen={isStatusModalOpen}
              onClose={() => {
                setIsStatusModalOpen(false);
                setPendingAssignment(null);
                setSelectedSections({});
              }}
              onConfirm={handleStatusModalConfirm}
              sections={
                (pendingAssignment.Sections as Array<
                  Section & { id: number }
                >) || []
              }
              selectedSections={selectedSections}
              setSelectedSections={setSelectedSections}
              onSectionToggle={handleSectionToggle}
            />
          )}
          <div className="col-span-full">
            <AttendanceChart />
          </div>
        </div>
      </div>
    </div>
  );
}

'use client';

import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import {
  Bookmark,
  CalendarIcon,
  Trash2,
  ChevronRight,
  Check,
  AlertTriangle,
  BookOpen,
  Clock,
} from 'lucide-react';
import _ from 'lodash';
import { surahNameMap } from '../utils/surahUtils';
import { useMutateAssignment } from '../hooks/useMutateAssignment';

import { Button } from './ui/button';
import { Calendar } from './ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from './ui/dialog';
import { ScrollArea } from './ui/scroll-area';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Badge } from './ui/badge';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './ui/tooltip';
import { Progress } from './ui/progress';
import HeaderNav from './HeaderNav';

import { cn } from '../lib/utils';

interface Section {
  surahNumber: number;
  startVerse: number;
  endVerse: number;
}

export default function ReviewAssignment() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [openCalendar, setOpenCalendar] = useState(false);
  const [date, setDate] = useState<Date | undefined>(undefined);
  const { mutateAssignment } = useMutateAssignment();

  const [sections, setSections] = useState<Section[]>(
    Object.entries(
      state.verses as Record<string, { startVerse: number; endVerse: number }>
    ).map(([key, value]) => ({
      surahNumber: parseInt(key),
      startVerse: value.startVerse,
      endVerse: value.endVerse,
    }))
  );

  const [deleteModalState, setDeleteModalState] = useState<{
    isOpen: boolean;
    surahNumber: number | null;
  }>({
    isOpen: false,
    surahNumber: null,
  });

  const handleDeleteClick = (surahNumber: number) => {
    setDeleteModalState({
      isOpen: true,
      surahNumber,
    });
  };

  const handleConfirmDelete = () => {
    if (deleteModalState.surahNumber) {
      setSections(
        sections.filter(
          (section) => section.surahNumber !== deleteModalState.surahNumber
        )
      );
      setDeleteModalState({ isOpen: false, surahNumber: null });
    }
  };

  const handleSubmitLesson = async () => {
    const studentId = state.user.id;
    const assignment = {
      ...state.assignment,
      ...(date != null ? { dueDate: date } : {}),
      status: 'draft',
    };

    const requestBody = {
      assignment,
      sections,
    };

    await mutateAssignment(studentId, requestBody, state.mode);
  };

  const totalVerses = sections.reduce(
    (acc, section) => acc + (section.endVerse - section.startVerse + 1),
    0
  );

  const estimatedCompletionDays = Math.ceil(totalVerses / 10);
  const progressPercentage = Math.min((totalVerses / 50) * 100, 100);

  if (state?.user == null) {
    return (
      <div className="p-4 text-center text-muted-foreground">
        You do not have permission to view this page.
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <HeaderNav title={`${state.user.name}`} />
      <div className="container max-w-7xl mx-auto p-4 pt-8">
        <div className="mb-8">
          <h1 className="text-4xl font-bold mb-2">Review Lesson</h1>
          <p className="text-xl text-gray-600">Finalize your lesson details</p>
        </div>
        <div className="flex flex-col lg:flex-row gap-8">
          <div className="lg:w-2/3">
            <Card className="rounded-3xl shadow-lg overflow-hidden">
              <CardHeader className="bg-gradient-to-r from-teal-500 to-teal-600 p-6">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-3">
                    <div className="bg-white/20 rounded-lg p-2">
                      <Bookmark className="h-5 w-5 text-white" />
                    </div>
                    <CardTitle className="text-2xl font-semibold text-white">
                      {_.startCase(_.toLower(state.type))} Lesson
                    </CardTitle>
                  </div>
                  {date && (
                    <Badge
                      variant="secondary"
                      className="text-sm px-3 py-1 bg-white/20 text-white"
                    >
                      Due {format(date, 'MMM d, yyyy')}
                    </Badge>
                  )}
                </div>
              </CardHeader>
              <CardContent className="p-0">
                <ScrollArea className="h-[400px] lg:h-[500px]">
                  {sections.map((section) => (
                    <div
                      key={section.surahNumber}
                      className="p-4 sm:p-6 border-b last:border-b-0 transition-all duration-300 ease-in-out hover:bg-gray-50"
                    >
                      <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
                        <div className="flex items-center space-x-4">
                          <div className="bg-gradient-to-br from-teal-500 to-teal-600 rounded-full p-2 flex-shrink-0">
                            <ChevronRight className="h-5 w-5 text-white" />
                          </div>
                          <div>
                            <div className="text-lg font-semibold text-gray-800">
                              {surahNameMap[section.surahNumber]}
                            </div>
                            <div className="text-sm text-teal-600 font-semibold">
                              Surah {section.surahNumber}
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center justify-between sm:justify-end w-full sm:w-auto gap-4">
                          <Badge
                            variant="outline"
                            className="text-sm px-3 py-1 text-gray-700 bg-white border flex-shrink-0"
                          >
                            Verses {section.startVerse}-{section.endVerse}
                          </Badge>
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Button
                                  variant="ghost"
                                  size="icon"
                                  onClick={() =>
                                    handleDeleteClick(section.surahNumber)
                                  }
                                  className="text-red-500 hover:text-red-500 hover:bg-red-100 rounded-full transition-colors duration-300"
                                >
                                  <Trash2 className="h-5 w-5" />
                                </Button>
                              </TooltipTrigger>
                              <TooltipContent>
                                <p>Delete section</p>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </div>
                      </div>
                    </div>
                  ))}
                </ScrollArea>
              </CardContent>
            </Card>
          </div>
          <div className="lg:w-1/3 space-y-6">
            <Card className="rounded-3xl shadow-lg overflow-hidden">
              <CardHeader className="bg-gradient-to-r from-teal-500 to-teal-600 p-6">
                <CardTitle className="text-xl font-semibold text-white flex items-center">
                  <div className="bg-white/20 rounded-lg p-2 mr-2">
                    <BookOpen className="h-5 w-5 text-white" />
                  </div>
                  Lesson Overview
                </CardTitle>
              </CardHeader>
              <CardContent className="p-6 space-y-6">
                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-gray-100 rounded-3xl p-4 text-center">
                    <div className="text-sm font-medium text-gray-600 mb-1">
                      Total Sections
                    </div>
                    <div className="text-3xl font-bold text-teal-600">
                      {sections.length}
                    </div>
                  </div>
                  <div className="bg-gray-100 rounded-3xl p-4 text-center">
                    <div className="text-sm font-medium text-gray-600 mb-1">
                      Total Verses
                    </div>
                    <div className="text-3xl font-bold text-teal-600">
                      {totalVerses}
                    </div>
                  </div>
                </div>
                <div className="space-y-2">
                  <div className="flex justify-between items-center">
                    <span className="text-sm font-medium text-gray-800 flex items-center">
                      <Clock
                        className="mr-2 h-4 w-4 text-teal-600"
                        strokeWidth={2.5}
                      />
                      Estimated Completion:
                    </span>
                    <span className="text-lg font-semibold text-teal-600">
                      {estimatedCompletionDays} days
                    </span>
                  </div>
                  <Progress value={progressPercentage} className="h-2" />
                </div>
                <div>
                  <label
                    htmlFor="due-date"
                    className="text-sm font-medium block mb-2"
                  >
                    Set Due Date
                  </label>
                  <Popover open={openCalendar} onOpenChange={setOpenCalendar}>
                    <PopoverTrigger asChild>
                      <Button
                        id="due-date"
                        variant="outline"
                        className={cn(
                          'w-full justify-start text-left rounded-xl bg-gray-100 border-none',
                          !date && 'text-gray-500'
                        )}
                      >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {date ? format(date, 'PPP') : <span>Pick a date</span>}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent
                      className="w-auto p-0 rounded-xl"
                      align="start"
                    >
                      <Calendar
                        mode="single"
                        selected={date}
                        onSelect={(value) => {
                          setDate(value);
                          setOpenCalendar(false);
                        }}
                        initialFocus
                        className="rounded-xl"
                      />
                    </PopoverContent>
                  </Popover>
                </div>
              </CardContent>
            </Card>
            <div className="flex flex-col space-y-4">
              <Button
                size="lg"
                onClick={handleSubmitLesson}
                disabled={sections.length === 0}
                className="w-full bg-gradient-to-r from-teal-500 to-teal-600 hover:from-teal-600 hover:to-teal-700 text-white rounded-xl py-3 text-lg font-semibold transition-all duration-300 shadow-md hover:shadow-lg"
              >
                <Check className="mr-2 h-5 w-5" />
                {_.startCase(state.mode ?? 'Submit')} Lesson
              </Button>
              <Button
                variant="outline"
                size="lg"
                onClick={() =>
                  navigate(`/student/${state.user.id}`, {
                    state,
                    replace: true,
                  })
                }
                className="w-full rounded-xl py-3 text-lg font-semibold border-gray-300 text-gray-700 hover:bg-gray-50 transition-all duration-300"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={deleteModalState.isOpen}
        onOpenChange={() =>
          setDeleteModalState({ isOpen: false, surahNumber: null })
        }
      >
        <DialogContent className="sm:max-w-md rounded-3xl">
          <DialogHeader>
            <DialogTitle className="flex items-center text-xl font-semibold">
              <AlertTriangle className="h-6 w-6 text-red-500 mr-2" />
              Delete Section
            </DialogTitle>
            <DialogDescription>
              Are you sure you want to delete Surah{' '}
              {deleteModalState.surahNumber
                ? surahNameMap[deleteModalState.surahNumber]
                : ''}
              ? This action cannot be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="flex justify-end space-x-2 mt-6">
            <Button
              variant="outline"
              onClick={() =>
                setDeleteModalState({ isOpen: false, surahNumber: null })
              }
              className="rounded-xl hover:bg-gray-50"
            >
              Cancel
            </Button>
            <Button
              variant="destructive"
              onClick={handleConfirmDelete}
              className="rounded-xl bg-red-500 hover:bg-red-600"
            >
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardHeader, CardContent } from './ui/card';
import { Button } from './ui/button';
import mid from '../assets/mid.png';
import { FormEvent, useState } from 'react';
import { Alert } from './ui/alert';
import { useAuth } from '../contexts/AuthContext';
import { isValidEmail } from '../utils/validation';
import { BackendError } from '../types/error';
import { useToast } from '../hooks/useToast';
import { CircleAlert, Lock, User } from 'lucide-react';
import { Input } from './ui/input';

interface LoginFormErrors {
  username?: string;
  password?: string;
  general?: BackendError | string;
}

export function Login() {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errors, setErrors] = useState<LoginFormErrors>({});
  const { login } = useAuth();
  const navigate = useNavigate();
  const { toast } = useToast();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<string | null>();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrors({});

    if (!validateForm()) {
      return;
    }

    const result = await login(username, password);
    if (result.success) {
      navigate(result.isAtLeastTeacher ? '/students' : '/dashboard', {
        replace: true,
      });
    } else {
      const title =
        typeof result.error === 'string' ? result.error : result.error?.title;
      const description =
        typeof result.error === 'string' ? result.error : result.error?.message;
      toast({
        title,
        description,
        icon: (
          <CircleAlert className="text-white dark:text-slate-900" fill="red" />
        ),
      });
      setErrors({ general: result.error || 'An unknown error occurred' });
    }
  };

  const validateForm = (): boolean => {
    const newErrors: LoginFormErrors = {};

    if (!username) {
      newErrors.username = 'Username is required';
    } else if (!isValidEmail(username)) {
      newErrors.username = 'Username must be a valid email address';
    }

    if (!password) {
      newErrors.password = 'Password is required';
    } else if (password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <div className="relative flex items-start justify-center min-h-screen overflow-hidden">
      <div className="absolute w-[100rem] lg:w-[100vw] h-[100rem] bg-primary -top-[82rem] left-1/2 -translate-x-1/2 z-0"></div>
      <div
        className="absolute w-[100rem] lg:w-[100vw] h-[100rem] bg-primary -top-[32rem] left-1/2 -translate-x-1/2 z-0"
        style={{ clipPath: 'polygon(0% 50%, 50% 73%, 100% 50%)' }}
      ></div>

      <div className="z-10">
        <img src={mid} alt="Logo" className="w-40 h-40 mx-auto mt-7" />

        <CardHeader className="text-white font-bold text-center py-2 my-5 bg-primary text-3xl">
          HIFZ TRACKER
        </CardHeader>
        <Card className="relative z-10 w-[350px] mx-auto rounded-3xl overflow-hidden bg-transparent border-none shadow-md">
          <CardContent className="p-8 bg-white rounded-lg">
            {error && <Alert variant={'destructive'}>{error}</Alert>}
            <form onSubmit={handleSubmit}>
              <div className="mb-7">
                <label htmlFor="username" className="text-primary block mb-2">
                  Username
                </label>
                <Input
                  icon={<User className="w-5 h-5 flex-shrink-0 opacity-50" />}
                  id="username"
                  type="email"
                  value={username}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setUsername(e.target.value)
                  }
                  placeholder="Your Email Address"
                  className={`${
                    errors.username ? 'border-red-500' : 'border-gray-300'
                  }`}
                  required
                />
                {errors.username && (
                  <p className="text-red-500 text-sm">{errors.username}</p>
                )}
              </div>

              <div className="mb-7">
                <label htmlFor="password" className="text-primary block mb-2">
                  Password
                </label>
                <Input
                  icon={<Lock className="w-5 h-5 flex-shrink-0 opacity-50" />}
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPassword(e.target.value)
                  }
                  placeholder="Enter your password"
                  className={` ${
                    errors.password ? 'border-red-500' : 'border-gray-300'
                  }`}
                  required
                />
                {errors.password && (
                  <p className="text-red-500 text-sm">{errors.password}</p>
                )}
              </div>

              <div className="mb-7">
                <Button
                  className="w-full bg-primary py-6 rounded-xl"
                  type="submit"
                >
                  SIGN IN
                </Button>
              </div>

              <p className="text-center text-gray-600">
                Forgot User ID or Password?
              </p>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

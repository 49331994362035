import React from 'react';

interface VoiceVisualizerProps {
  audioData: number[];
  isRecording: boolean;
}

export const VoiceVisualizer: React.FC<VoiceVisualizerProps> = ({
  audioData,
  isRecording,
}) => {
  const normalizedData =
    audioData.length > 0
      ? audioData.filter((_, index) => index % 4 === 0)
      : new Array(32).fill(1);

  return (
    <div className="w-full h-28 sm:h-32 flex justify-center items-center overflow-hidden rounded-3xl">
      {normalizedData.map((value, index) => {
        const height =
          audioData.length > 0 ? Math.max(5, (value / 255) * 100) : 5;
        return (
          <div
            key={index}
            className="h-full flex flex-col justify-center items-center"
            style={{ width: `${100 / normalizedData.length}%` }}
          >
            <div
              className="w-[4px] mx-[1px] bg-teal-500 rounded-full transition-all duration-100 ease-out"
              style={{
                height: `${height}%`,
                maxHeight: '70%',
                opacity: isRecording ? 1 : 0.5,
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

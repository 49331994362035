import React from 'react';
import api from '../services/axios';
import { toast } from './useToast';
import { CircleAlert } from 'lucide-react';

export const useGetAssignments = (userId?: number | string) => {
  const [assignments, setAssignments] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<any>(null);
  const url =
    userId != null
      ? `/api/user/${userId}/assignments`
      : `/api/user/assignments`;

  const fetchAssignments = React.useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get(url);
      setAssignments(response.data);
      setError(null);
    } catch (error: any) {
      console.error(error);
      const title = error?.response?.data?.title ?? 'An Error has occurred';
      const description =
        error?.response?.data?.message ?? 'Lesson could not be created';
      toast({
        title,
        description,
        icon: (
          <CircleAlert className="text-white dark:text-slate-900" fill="red" />
        ),
      });
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [url]);

  React.useEffect(() => {
    fetchAssignments();
  }, [fetchAssignments]);

  return {
    assignments,
    loading,
    error,
    refetch: fetchAssignments,
  };
};

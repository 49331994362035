import { useState, useEffect } from 'react';
import api from '../services/axios';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { BackendError } from '../types/error';
import { User } from '../types/common';

interface AttendanceList {
  forDate: Date;
  users: User[];
}

export const useGetUsers = (selectedDate?: Date) => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const dateParam = selectedDate
      ? `?date=${selectedDate.toISOString().split('T')[0]}`
      : '';

    api
      .get<AttendanceList>(`/api/attendance${dateParam}`)
      .then((response) => {
        setUsers(response.data?.users);
      })
      .catch((err: AxiosError<BackendError>) => {
        const statusCode = err.response?.status;
        if (statusCode === 403) {
          navigate(`/login`);
        }
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [navigate, selectedDate]);

  return { users, loading, error };
};
